/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconsPlug1 = ({ className }) => {
  return (
    <svg
      className={`icons-plug-1 ${className}`}
      fill="none"
      height="128"
      viewBox="0 0 129 128"
      width="129"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M77.128 54.5489V47.3922H73.7882V54.4693H69.7328V47.3922H66.393V54.4693H62.3375V47.3922H58.9977V54.4693H54.9423V47.3922H51.6025V54.5489C49.0579 54.8669 47.1494 57.0139 47.1494 59.5585V70.1346C47.1494 74.8262 50.9663 78.5636 55.5784 78.5636H62.7351V105.6H66.0749V78.5636H73.2316C77.9232 78.5636 81.6606 74.7466 81.6606 70.1346V59.5585C81.6606 56.9344 79.6726 54.7874 77.128 54.5489ZM78.2413 70.1346C78.2413 72.9177 75.9352 75.2238 73.1521 75.2238H55.4194C52.6362 75.2238 50.3302 72.9177 50.3302 70.1346V59.5585C50.3302 58.6043 51.1254 57.8887 52.0001 57.8887H76.4919C77.4461 57.8887 78.1618 58.6838 78.1618 59.5585L78.2413 70.1346Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M89.6126 41.8259C89.6126 35.9415 84.921 31.1704 79.1161 31.0113C76.7305 25.0474 70.9257 20.9919 64.4051 20.9919C57.8846 20.9919 52.0797 25.0474 49.6941 31.0113C43.8892 31.1704 39.1976 35.9415 39.1976 41.7464C34.9036 43.2572 31.9614 47.3127 31.9614 51.9248C31.9614 57.8887 36.8121 62.6598 42.6965 62.6598H47.07V59.4791C47.07 59.3996 47.07 59.3995 47.07 59.32H42.6965C38.641 59.32 35.3012 55.9802 35.3012 51.9248C35.3012 48.426 37.8458 45.3247 41.2651 44.6886C42.1398 44.5295 42.776 43.6548 42.6169 42.7801C42.5374 42.3825 42.5374 42.0644 42.5374 41.7464C42.5374 37.6909 45.8772 34.3511 49.9327 34.3511C50.1712 34.3511 50.4098 34.3511 50.7279 34.4306C51.5231 34.5102 52.3182 34.033 52.5568 33.2379C53.9881 28.0691 58.9183 24.4113 64.3256 24.4113C69.7329 24.4113 74.663 28.0691 76.1739 33.2379C76.4125 34.033 77.1281 34.5102 78.0028 34.4306C78.2414 34.4306 78.4799 34.3511 78.798 34.3511C82.8535 34.3511 86.1933 37.6909 86.1933 41.7464C86.1933 42.0644 86.1933 42.3825 86.1138 42.7801C85.9547 43.6548 86.5909 44.5295 87.4656 44.6886C90.8849 45.4042 93.4295 48.426 93.4295 51.9248C93.4295 55.9802 90.0897 59.32 86.0342 59.32H81.6607C81.6607 59.3995 81.6607 59.3996 81.6607 59.4791V62.6598H86.0342C91.9981 62.6598 96.7693 57.8092 96.7693 51.9248C96.7693 47.3922 93.8271 43.3367 89.6126 41.8259Z"
        fill="#A591D7"
      />
    </svg>
  );
};
