/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconsCloudLaptop = ({ className }) => {
  return (
    <svg
      className={`icons-cloud-laptop ${className}`}
      fill="none"
      height="128"
      viewBox="0 0 128 128"
      width="128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M93.8238 81.6001H34.1758C29.5038 81.6001 25.6638 77.7601 25.6638 73.0881V39.0401C25.6638 34.3681 29.5038 30.5281 34.1758 30.5281H93.7598C98.4318 30.5281 102.272 34.3681 102.272 39.0401V73.1521C102.272 77.8241 98.4958 81.6001 93.8238 81.6001ZM34.1758 33.9201C31.3598 33.9201 29.0558 36.2241 29.0558 39.0401V73.1521C29.0558 75.9681 31.3598 78.2721 34.1758 78.2721H93.7598C96.5758 78.2721 98.8798 75.9681 98.8798 73.1521V39.0401C98.8798 36.2241 96.5758 33.9201 93.7598 33.9201H34.1758Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M103.168 97.4722H24.8319C22.0159 97.4722 19.7119 95.1682 19.7119 92.3522V89.6642C19.7119 86.8482 22.0159 84.5442 24.8319 84.5442H103.104C105.92 84.5442 108.224 86.8482 108.224 89.6642V92.3522C108.224 95.2322 105.984 97.4722 103.168 97.4722ZM24.8319 88.0002C23.8719 88.0002 23.1039 88.7682 23.1039 89.7282V92.4162C23.1039 93.3762 23.8719 94.1442 24.8319 94.1442H103.104C104.064 94.1442 104.832 93.3762 104.832 92.4162V89.7282C104.832 88.7682 104.064 88.0002 103.104 88.0002H24.8319Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M77.6959 69.888H50.3039C46.2079 69.888 42.8799 66.56 42.8799 62.464C42.8799 59.392 44.7359 56.704 47.4879 55.552C47.6799 51.84 50.6239 48.832 54.3359 48.576C55.9359 44.8 59.7759 42.24 63.9999 42.24C68.2239 42.24 72.0639 44.8 73.7279 48.576C77.4399 48.896 80.3839 51.84 80.5759 55.552C83.2639 56.704 85.1199 59.392 85.1199 62.4C85.1839 66.56 81.8559 69.888 77.6959 69.888ZM54.8479 51.968C52.6079 51.968 50.8159 53.76 50.8159 56C50.8159 56.192 50.8159 56.32 50.8799 56.576C51.0079 57.472 50.4319 58.304 49.5359 58.496C47.6159 58.88 46.2719 60.544 46.2719 62.464C46.2719 64.704 48.0639 66.496 50.3039 66.496H77.7599C79.9999 66.496 81.7919 64.704 81.7919 62.464C81.7919 60.544 80.4479 58.88 78.5279 58.496C77.6319 58.304 77.0559 57.472 77.1839 56.576C77.1839 56.384 77.2479 56.192 77.2479 56C77.2479 53.76 75.4559 51.968 73.2159 51.968C73.0879 51.968 72.9599 51.968 72.7679 51.968C71.9359 52.032 71.1679 51.52 70.9759 50.752C70.0799 47.744 67.1999 45.568 64.0639 45.568C60.8639 45.568 58.0479 47.68 57.1519 50.752C56.8959 51.52 56.1919 52.032 55.3599 51.968C55.1679 51.968 55.0399 51.968 54.8479 51.968Z"
        fill="#A591D7"
      />
    </svg>
  );
};
