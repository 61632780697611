import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header"; // Import your Header component
import "./style.css"; // Import your CSS file for Blog styling
import Footer from "../../components/Footer/Footer";
import Subscription from "../../components/Subscription/Subscription";
import TrailbluIntro from "../../assets/TrailbluIntro.png";
import { Helmet } from "react-helmet-async";
import { marked } from "marked";
import P31 from "../../assets/p31.png";
import P3 from "../../assets/p3.png";
import P2 from "../../assets/p2.png";
import P21 from "../../assets/p21.png";
import P22 from "../../assets/p22.png";
import CodelessAutomated from "../../assets/codelessAutomated.png";
import VisualTesting from "../../assets/visualTesting.png";
import FlutterTesting from "../../assets/flutterTesting.png";
import FlutterTesting2 from "../../assets/flutterTesting2.png";
import FlutterTesting3 from "../../assets/flutterTesting3.png";
import GenerativeAIimage from "../../assets/generativeAI.png";
import GenerativeAIimage2 from "../../assets/generativeAI2.png";
import GenerativeAIimage3 from "../../assets/generativeAI3.png";
import Qatar from "../../assets/qatar.png";
import QatarEvent from "../../assets/qatar_event2.png";
import RevolutionizeTestingAIImage1 from "../../assets/revolutionizetestingAI1.png";
import RevolutionizeTestingAIImage2 from "../../assets/revolutionizetestingAI2.png";
import ContinuousTestingImage1 from "../../assets/ContinuousTesting1.png";
import ContinuousTestingImage2 from "../../assets/ContinuousTesting2.png";
import RegressionTestingImage1 from "../../assets/RegressionTesting1.png";

export const SpecificBlog = ({
  label,
  blogImage,
  title,
  metaDescription,
  metaTitle,
  authorName,
  date,
  readTime,
  content,
  authorImageUrl,
}) => {
  const replaceImages = (markdown) => {
    return markdown
      .replace("/selfHealing.png", P31)
      .replace("/codelessAutomated.png", CodelessAutomated)
      .replace("/P2.png", P2)
      .replace("/P3.png", P3)
      .replace("/P21.png", P21)
      .replace("./P22.png", P22)
      .replace("/visualTesting.png", VisualTesting)
      .replace("/FlutterTesting.png", FlutterTesting)
      .replace("/flutterTesting2.png", FlutterTesting2)
      .replace("/flutterTesting3.png", FlutterTesting3)
      .replace("/GenerativeAI.png", GenerativeAIimage)
      .replace("/GenerativeAI2.png", GenerativeAIimage2)
      .replace("/GenerativeAI3.png", GenerativeAIimage3)
      .replace("/revolutionizetestingAI1.png", RevolutionizeTestingAIImage1)
      .replace("/revolutionizetestingAI2.png", RevolutionizeTestingAIImage2)
      .replace("/ContinuousTesting1.png", ContinuousTestingImage1)
      .replace("/ContinuousTesting2.png", ContinuousTestingImage2)
      .replace("/RegressionTesting1.png", RegressionTestingImage1)
      .replace("/qatar.png", Qatar)
      .replace("/qatar_event2.png", QatarEvent);
  };
  const replacedImagesContent = replaceImages(content);
  const markedToHtml = marked.parse(replacedImagesContent);

  const [markdownContent, setMarkdownContent] = useState("");
  const createSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "");
  };

  useEffect(() => {
    setMarkdownContent(content);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />

        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={blogImage} />
        <meta name="twitter:image:alt" content="TRAILBLU Blog Image" />
        <meta name="twitter:card" content="	summary_large_image" />
        <meta name="twitter:site" content="@trailblu" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={blogImage} />
        <meta property="og:image:alt" content="TRAILBLU Blog Image" />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content={`https://www.trailblu.com/blog/${createSlug(title)}`}
        />
        <meta property="og:site_name" content="Trailblu Blog" />
        <meta property="og:image:height" content="600" />

        {/* Other meta tags can be added similarly */}
      </Helmet>
      <Header />
      <div className="hero-section-2">
        <div className="section-2">
          <div className="starter-container">
            <div className="label-2">{label}</div>
            <div className="middle-starter">
              <h1 className="h1-class-2">{title}</h1>
              <div className="author">
                {/* <div className="author-img2">
                  <img
                    className="image"
                    src={authorImageUrl}
                    alt="Trailblu author image"
                  />
                </div> */}
                {/* <div className="author-name">{authorName}</div> */}
              </div>
            </div>
            <div className="divider-section-2">
              <div className="date-read-section">
                <div>{date}</div>
                <div className="date-divider"></div>
                <div className="read-time">
                  <div>{readTime}</div>
                  <div className="min-read-txt">min read</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-section">
        <div className="content-container">
          <div className="content">
            <div dangerouslySetInnerHTML={{ __html: markedToHtml }} />
          </div>
        </div>
      </div>
      <Subscription />
      <Footer />
    </div>
  );
};

export default SpecificBlog;
