import React, { useEffect } from "react";
import { useParams, Navigate } from "react-router-dom";
import Header from "../../components/Header/Header"; // Import your Header component
import "./style.css"; // Import your CSS file for Blog styling
import Footer from "../../components/Footer/Footer";
import { Button } from "../../components/Button";
import { ComparisonTable } from "../../components/ComparisonTable/ComparisonTable";
import { IconsCloudLaptop } from "../../icons/IconsCloudLaptop";
import { IconsPhone } from "../../icons/IconsPhone";
import { IconsPlug1 } from "../../icons/IconsPlug1";
import { Helmet } from "react-helmet-async";
import TrailbluIntro from "../../assets/TrailbluIntro.png";

export const ComparisonPage = () => {
  const metaInfo = {
    Appium: {
      metaTitle: "Trailblu vs Appium: Comprehensive Test Automation Comparison",
      metaDescription:
        "Compare Trailblu and Appium for mobile and web app testing. Explore features like parallel tests, no-code testing, AI-powered self-healing, and more.",
      // Other meta tags for Appium...
    },
    Selenium: {
      metaTitle:
        "Trailblu vs Selenium: Comprehensive Test Automation Comparison",
      metaDescription:
        "Compare Trailblu and Selenium for web app testing. Analyze features such as no-code testing, AI-powered self-healing, visual testing, data-driven testing, and more.",
      // Other meta tags for Selenium...
    },
    Katalon: {
      metaTitle:
        "Trailblu vs Katalon: Comprehensive Test Automation Tools Comparison",
      metaDescription:
        "Compare Trailblu and Katalon for web and mobile app testing. Analyze features including AI-powered self-healing, organization management, test scheduling, and Slack/email notifications.",
      // Other meta tags for Katalon...
    },
    Preflight: {
      metaTitle:
        "Trailblu vs Preflight: Thorough Test Automation Platform Comparison",
      metaDescription:
        "Evaluate Trailblu against Preflight for comprehensive web and mobile app testing. Assess features including live manual app testing, visual testing, data-driven testing, and more.",
      // Other meta tags for Preflight...
    },
    Mabl: {
      metaTitle: "Trailblu vs Mabl: Detailed Test Automation Comparison",
      metaDescription:
        "Compare Trailblu and Mabl for web and mobile app testing. Evaluate features such as AI-powered self-healing, visual testing, test scheduling, and Slack/email notifications.",
      // Other meta tags for Mabl...
    },
    Functionize: {
      metaTitle:
        "Trailblu vs Functionize: Comprehensive Test Automation Tool Comparison",
      metaDescription:
        "Assess Trailblu and Functionize for web and mobile app testing. Compare features like AI-powered self-healing, data-driven testing, test scheduling, CI/CD integrations, and Slack/email notifications.",
      // Other meta tags for Functionize...
    },
    "Ghost Inspector": {
      metaTitle:
        "Trailblu vs Ghost Inspector: Thorough Test Automation Comparison",
      metaDescription:
        "Compare Trailblu and Ghost Inspector for web and mobile app testing. Examine features including visual testing, data-driven testing, organization management, and more.",
      // Other meta tags for GhostInspector...
    },
    Reflect: {
      metaTitle:
        "Trailblu vs Reflect: Comprehensive Test Automation Platform Comparison",
      metaDescription:
        "Compare Trailblu and Reflect for web and mobile app testing. Explore features including live manual app testing, organization management, visual testing, and more.",
      // Other meta tags for Reflect...
    },
    LambdaTest: {
      metaTitle:
        "Trailblu vs LambdaTest: Comprehensive Test Automation Comparison",
      metaDescription:
        "Compare Trailblu and LambdaTest for web and mobile app testing. Evaluate features such as AI-powered self-healing, live manual app testing, test scheduling, and Slack/email notifications.",
      // Other meta tags for LambdaTest...
    },
    Cypress: {
      metaTitle: "Trailblu vs Cypress: Detailed Web Testing Tools Comparison",
      metaDescription:
        "Examine Trailblu and Cypress for web app testing. Compare parallel test capabilities, AI-powered self-healing, data-driven testing, CI/CD integrations, and Slack/email notification features.",
      // Other meta tags for Cypress...
    },
    "Testim.io": {
      metaTitle:
        "Trailblu vs Testim.io: Comprehensive Test Automation Comparison",
      metaDescription:
        "Compare Trailblu and Testim.io for web and mobile app testing. Evaluate features such as AI-powered self-healing, live manual app testing, test scheduling, and Slack/email notifications.",
      // Other meta tags for Testim.io...
    },
    "Sauce Labs": {
      metaTitle:
        "Trailblu vs Saucelab: Comprehensive Test Automation Platforms Comparison",
      metaDescription:
        "Compare Trailblu and Saucelab for comprehensive web and mobile app testing. Analyze parallel tests, no-code testing, visual testing, organization management, and more.",
      // Other meta tags for Saucelab...
    },
    // Add other companies and their meta data as needed...
  };

  const getComparisonData = (formattedCompanyName) => {
    switch (formattedCompanyName) {
      case "Appium":
        return [
          { feature: "Web App Testing", otherCompany: "No", trailblu: "yes" },
          {
            feature: "Mobile App Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered No-Code Testing",
            otherCompany: "No",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Self-Healing",
            otherCompany: "No",
            trailblu: "yes",
          },
          {
            feature: "Live Manual App Testing",
            otherCompany: "No",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Visual Testing",
            otherCompany: "No",
            trailblu: "yes",
          },
          {
            feature: "Data-Driven Testing",
            otherCompany: "No",
            trailblu: "yes",
          },
          {
            feature: "Organization Management",
            otherCompany: "No",
            trailblu: "yes",
          },
          { feature: "Test Scheduling", otherCompany: "No", trailblu: "yes" },
          {
            feature: "CI/CD integrations",
            otherCompany: "No",
            trailblu: "yes",
          },
          {
            feature: "Slack/Email notification",
            otherCompany: "No",
            trailblu: "yes",
          },
          { feature: "Detailed Reports", otherCompany: "No", trailblu: "yes" },
        ];

      case "Selenium":
        return [
          { feature: "Web App Testing", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "Mobile App Testing",
            otherCompany: "No",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered No-Code Testing",
            otherCompany: "Yes, limited",
            trailblu: "Yes",
          },
          {
            feature: "AI-Powered Self-Healing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "Live Manual App Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Visual Testing",
            otherCompany: "no",
            trailblu: "yes, inbuilt support",
          },
          {
            feature: "Data-Driven Testing",
            otherCompany: "Support via integrations",
            trailblu: "yes, inbuilt support",
          },
          {
            feature: "Organization Management",
            otherCompany: "no",
            trailblu: "yes",
          },
          { feature: "Test Scheduling", otherCompany: "no", trailblu: "yes" },
          {
            feature: "CI/CD integrations",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "Slack/Email notification",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "Detailed Reports",
            otherCompany: "no",
            trailblu: "yes, inbuilt support",
          },
        ];
      case "Katalon":
        return [
          { feature: "Web App Testing", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "Mobile App Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered No-Code Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Self-Healing",
            otherCompany: "Yes, limited",
            trailblu: "yes",
          },
          {
            feature: "Live Manual App Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Visual Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "Data-Driven Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Organization Management",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          { feature: "Test Scheduling", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "CI/CD integrations",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Slack/Email notification",
            otherCompany: "Yes",
            trailblu: "yes",
          },
        ];
      case "Preflight":
        return [
          { feature: "Web App Testing", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "Mobile App Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered No-Code Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Self-Healing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "Live Manual App Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Visual Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Data-Driven Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Organization Management",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          { feature: "Test Scheduling", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "CI/CD integrations",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Slack/Email notification",
            otherCompany: "Yes",
            trailblu: "yes",
          },
        ];
      case "Mabl":
        return [
          { feature: "Web App Testing", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "Mobile App Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered No-Code Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Self-Healing",
            otherCompany: "yes, limited",
            trailblu: "yes",
          },
          {
            feature: "Live Manual App Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "Visual Testing with Custom Settings",
            otherCompany: "No",
            trailblu: "yes",
          },
          {
            feature: "Data-Driven Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Organization Management",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          { feature: "Test Scheduling", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "CI/CD integrations",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Slack/Email notification",
            otherCompany: "Yes",
            trailblu: "yes",
          },
        ];
      case "Functionize":
        return [
          { feature: "Web App Testing", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "Mobile App Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered No-Code Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Self-Healing",
            otherCompany: "yes, limited",
            trailblu: "yes",
          },
          {
            feature: "Live Manual App Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Visual Testing",
            otherCompany: "yes, for simple workflows",
            trailblu: "yes",
          },
          {
            feature: "Data-Driven Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Organization Management",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          { feature: "Test Scheduling", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "CI/CD integrations",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Slack/Email notification",
            otherCompany: "Yes",
            trailblu: "yes",
          },
        ];
      case "Ghost Inspector":
        return [
          { feature: "Web App Testing", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "Mobile App Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered No-Code Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Self-Healing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "Live Manual App Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Visual Testing",
            otherCompany: "yes",
            trailblu: "yes",
          },
          {
            feature: "Data-Driven Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Organization Management",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          { feature: "Test Scheduling", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "CI/CD integrations",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Slack/Email notification",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Live Customer Support",
            otherCompany: "no",
            trailblu: "yes",
          },
        ];
      case "Reflect":
        return [
          { feature: "Web App Testing", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "Mobile App Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered No-Code Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Self-Healing",
            otherCompany: "yes, limited",
            trailblu: "yes",
          },
          {
            feature: "Live Manual App Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Visual Testing",
            otherCompany: "yes",
            trailblu: "yes",
          },
          {
            feature: "Data-Driven Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Organization Management",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          { feature: "Test Scheduling", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "CI/CD integrations",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Slack/Email notification",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Live Customer Support",
            otherCompany: "yes",
            trailblu: "yes",
          },
        ];
      case "LambdaTest":
        return [
          { feature: "Web App Testing", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "Mobile App Testing",
            otherCompany: "yes",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered No-Code Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Self-Healing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "Live Manual App Testing",
            otherCompany: "yes",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Visual Testing",
            otherCompany: "yes",
            trailblu: "yes",
          },
          {
            feature: "Data-Driven Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Organization Management",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          { feature: "Test Scheduling", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "CI/CD integrations",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Slack/Email notification",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Live Customer Support",
            otherCompany: "yes",
            trailblu: "yes",
          },
        ];
      case "Cypress":
        return [
          { feature: "Web App Testing", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "Mobile App Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered No-Code Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Self-Healing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "Live Manual App Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Visual Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "Data-Driven Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Organization Management",
            otherCompany: "no",
            trailblu: "yes",
          },
          { feature: "Test Scheduling", otherCompany: "no", trailblu: "yes" },
          {
            feature: "CI/CD integrations",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Slack/Email notification",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "Live Customer Support",
            otherCompany: "no",
            trailblu: "yes",
          },
        ];
      case "Testim.io":
        return [
          { feature: "Web App Testing", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "Mobile App Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered No-Code Testing",
            otherCompany: "yes",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Self-Healing",
            otherCompany: "yes, limited",
            trailblu: "yes",
          },
          {
            feature: "Live Manual App Testing",
            otherCompany: "yes, limited",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Visual Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "Data-Driven Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Organization Management",
            otherCompany: "yes",
            trailblu: "yes",
          },
          { feature: "Test Scheduling", otherCompany: "yes", trailblu: "yes" },
          {
            feature: "CI/CD integrations",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Slack/Email notification",
            otherCompany: "yes",
            trailblu: "yes",
          },
          {
            feature: "Live Customer Support",
            otherCompany: "yes",
            trailblu: "yes",
          },
        ];
      case "Sauce Labs":
        return [
          { feature: "Web App Testing", otherCompany: "Yes", trailblu: "yes" },
          {
            feature: "Mobile App Testing",
            otherCompany: "yes",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered No-Code Testing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Self-Healing",
            otherCompany: "no",
            trailblu: "yes",
          },
          {
            feature: "Live Manual App Testing",
            otherCompany: "yes",
            trailblu: "yes",
          },
          {
            feature: "AI-Powered Visual Testing",
            otherCompany: "yes",
            trailblu: "yes",
          },
          {
            feature: "Data-Driven Testing",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Organization Management",
            otherCompany: "yes",
            trailblu: "yes",
          },
          { feature: "Test Scheduling", otherCompany: "no", trailblu: "yes" },
          {
            feature: "CI/CD integrations",
            otherCompany: "Yes",
            trailblu: "yes",
          },
          {
            feature: "Slack/Email notification",
            otherCompany: "yes",
            trailblu: "yes",
          },
          {
            feature: "Live Customer Support",
            otherCompany: "no",
            trailblu: "yes",
          },
        ];

      default:
        return [];
    }
  };

  const { companyName } = useParams();
  const formattedCompanyName = companyName
    .split("-") // Split the string by "-"
    .slice(2) // Get the part after "trailblu-vs-"
    .join(" "); // Join using empty space to get "Ghost Inspector"
  const allowedCompanies = [
    "Appium",
    "Katalon",
    "Preflight",
    "Cypress",
    "Functionize",
    "Reflect",
    "Testim.io",
    "Selenium",
    "Mabl",
    "Ghost Inspector",
    "LambdaTest",
    "Sauce Labs",
  ];

  if (!allowedCompanies.includes(formattedCompanyName)) {
    // If not allowed, navigate to a specific error page or display an error message
    return <Navigate to="/error" replace />;
    // Alternatively, you can display an error message here instead of navigating
  }

  const comparisonData = getComparisonData(formattedCompanyName);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { metaTitle, metaDescription } = metaInfo[formattedCompanyName];

  return (
    <div className="main-3">
      <Helmet>
        <title>The Best {formattedCompanyName} Alternative | TRAILBLU</title>
        <meta
          name="description"
          content={`Explore Trailblu, the ultimate ${formattedCompanyName} alternative, offering a powerful platform for seamless automated end-to-end AI-Based software testing.`}
        />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={TrailbluIntro} />
        <meta name="twitter:image:alt" content="TRAILBLU Image" />
        <meta name="twitter:card" content="	summary_large_image" />
        <meta name="twitter:site" content="@trailblu" />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={TrailbluIntro} />
        <meta property="og:image:alt" content="TRAILBLU Image" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://www.trailblu.com/alternatives/${companyName}`}
        />
        {/* Other meta tags can be added similarly */}
      </Helmet>
      <Header />
      <div className="hero-section-comp">
        <div className="center">
          <div className="bottom"></div>
          <div className="company-name-comp">
            <h1 className="h1-class-comp">Choose the Best:</h1>
            <h1 className="h1-class-comp">
              <span className="primary-color"> TRAILBLU - </span>{" "}
            </h1>
            <h1 className="h1-class-comp">
              The Ultimate {formattedCompanyName} Alternative
            </h1>
            <div className="center-bottom">
              <p className="h3-class-2">
                TRAILBLU offers a powerful software platform for effortless
                automated end-to-end testing of Web and Mobile Applications,
                simplifying test creation and execution to ensure flawless
                functionality across various digital landscapes.
              </p>
              <a href="/">
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="solid"
                  text="Learn More"
                  type="regular"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ComparisonTable
        company={formattedCompanyName}
        comparisonList={comparisonData}
      />
      <div className="frame-feature">
        <div className="feature">
          <div className="feature-top">
            <IconsCloudLaptop className="feature-icon" />
            <h2 className="feature-header">AI-Powered No-Code Testing</h2>
          </div>
          <p className="feature-text">
            Forget about tedious, time-consuming test writing. With No-Code
            Testing, you can quickly write tests and get fast insights.
          </p>
        </div>
        <div className="feature">
          <div className="feature-top">
            <IconsPhone className="feature-icon" />
            <h2 className="feature-header">AI-Powered Visual Testing</h2>
          </div>
          <p className="feature-text">
            Harness the power of AI to transform your visual quality assurance.
            Our tool provides comprehensive coverage, intuitive insights, and
            smart detection of even the most subtle visual issues.
          </p>
        </div>
        <div className="feature">
          <div className="feature-top">
            <IconsPlug1 className="feature-icon" />
            <h2 className="feature-header">AI-Powered Self-Healing</h2>
          </div>
          <p className="feature-text">
            With TRAILBLU&#39;s AI-powered self-healing, you can get more from
            your existing tests by automatically identifying and fixing flaky
            tests.
          </p>
        </div>
      </div>

      <div className="comparison-bottom">
        <div className="bottom-text-part">
          <p className="bottom-text-header">
            Looking for a comprehensive testing solution that simplifies your
            test runs?
          </p>
          <p className="bottom-text">
            <span className="primary-color-2"> Trailblu</span> provides a
            comprehensive platform designed for seamless test execution,
            simplifying the process across diverse testing needs. With its
            user-friendly interface and robust capabilities,{" "}
            <span className="primary-color-2"> Trailblu</span> ensures efficient
            and hassle-free test execution for various applications and
            scenarios.
          </p>
        </div>
        <a className="button-part" href="/request-a-demo">
          <Button
            className="design-component-instance-node-2"
            stateProp="normal"
            style="solid"
            text="Request a demo"
            type="regular"
          />
        </a>
      </div>

      <Footer />
    </div>
  );
};

export default ComparisonPage;
