import PropTypes from "prop-types";
import React, { useReducer, useState } from "react";
import "./style.css";

export const Input = ({ property1, className, placeholderText, PLACEHOLDERClassName, text, onChange, value, type, name, id, minlength }) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "default",
  });

  //const [inputValue, setInputValue] = useState("");

  //const handleInputChange = (event) => {
  //setInputValue(event.target.value);
  //onChange(event);
  //};

  return text === "Your message" ? (
    <textarea
      className={`input-custom ${state.property1} ${className}`}
      placeholder={placeholderText}
      rows="5" // Set the number of rows for the textarea
      required
      onChange={onChange}
      value={value}
      type={type}
      name={name}
      id={id}
      minLength={minlength}
    ></textarea>
  ) : (
    <input
      value={value}
      required
      //  className={`PLACEHOLDER ${PLACEHOLDERClassName}`}
      className={`input-custom ${state.property1} ${className}`}
      placeholder={placeholderText}
      onChange={onChange}
      type={type}
      name={name}
      id={id}
      minLength={minlength}
    />
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "variant-2",
      };

    case "mouse_leave":
      return {
        ...state,
        property1: "default",
      };
  }

  return state;
}

Input.propTypes = {
  property1: PropTypes.oneOf(["variant-2", "default"]),
  placeholderText: PropTypes.string,
};
