/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";


export const Button = ({ stateProp, type, style, className, text = "Request a demo", onClick }) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "normal",
    type: type || "regular",
    style: style || "solid",
  });

  return (
    <button
      className={`button ${state.style} ${state.state} ${state.type} ${className}`}
      onMouseEnter={() => {
        dispatch("mouse_enter");
      }}
      onMouseLeave={() => {
        dispatch("mouse_leave");
      }}
      onClick={onClick}
    >
      <div className="request-a-demo">{text}</div>
    </button>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "normal",
      };
  }

  return state;
}

Button.propTypes = {
  stateProp: PropTypes.oneOf(["hover", "normal"]),
  type: PropTypes.oneOf(["cta", "regular"]),
  style: PropTypes.oneOf(["text", "solid", "outline"]),
  text: PropTypes.string,
};

export default Button;