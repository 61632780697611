import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet-async";
import { Form } from "../../components/Form/Form";
import Fibabanka from "../../assets/coloredFibaBanka.png";
import Aktifbank from "../../assets/coloredAktifBank.png";
import Osigo from "../../assets/osigoLogo.png"
import LogoCompany from "../../assets/coloredLogo.png";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import "./style.css";

export const RequestADemo = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-demo">
      <Helmet>
        <title>
          Request a Demo | AI-Powered Test Automation Tool - TRAILBLU
        </title>
        <meta
          name="description"
          content="Experience seamless app testing with TRAILBLU's AI-powered tool. Request a demo today to explore effortless test automation for mobile and web apps – no code required!"
        />
      </Helmet>

      <div className="demo-double-section">
        {windowWidth < 700 && (
          <>
            <GoogleReCaptchaProvider reCaptchaKey="6Lebh0kmAAAAADBJ2_lCpmVKMTNtAKVmjCbn3bfv">
              <Form />
            </GoogleReCaptchaProvider>
          </>
        )}
        <div className="demo-first-section">
          <a href="/">
            <p className="home-style"> ← Go Back</p>
          </a>
          <div className="demo-first-inner-section">
            <h1 className="h1-demo">
              Experience the brilliance of AI-driven testing! Book your demo
              with
              <span className="primary-color-demo"> TRAILBLU</span> today.
            </h1>
            <div className="frame-5">
              <div className="frame-6-demo">
                <div className="text-wrapper-4">Trusted by</div>
                <div className="frame-7-demo">
                  <div className="img-wrapper2-demo">
                    <img
                      className="companies-demo"
                      alt="Fibabanka"
                      src={Fibabanka}
                    />
                  </div>
                  <div className="img-wrapper2-demo">
              <img className="companies-demo" alt="Osigo" src={Osigo} />
            </div>
                  <div className="img-wrapper2-demo">
                    <img
                      className="companies-demo"
                      alt="Aktifbank"
                      src={Aktifbank}
                    />
                  </div>
                  <div className="img-wrapper2-demo">
                    <img
                      className="companies-demo"
                      src={LogoCompany}
                      alt="LogoCompany"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {windowWidth > 700 && (
          <GoogleReCaptchaProvider reCaptchaKey="6Lebh0kmAAAAADBJ2_lCpmVKMTNtAKVmjCbn3bfv">
            <Form />
          </GoogleReCaptchaProvider>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default RequestADemo;
