/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconsPhone = ({ className }) => {
  return (
    <svg
      className={`icons-phone ${className}`}
      fill="none"
      height="128"
      viewBox="0 0 128 128"
      width="128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M83.2486 102.062H44.7517C40.5119 102.062 37.1201 98.5849 37.1201 94.43V29.9858C37.1201 25.746 40.5967 22.3542 44.7517 22.3542H83.2486C87.4883 22.3542 90.8801 25.8308 90.8801 29.9858V94.43C90.8801 98.6697 87.4883 102.062 83.2486 102.062ZM44.7517 25.4069C42.2078 25.4069 40.1727 27.4419 40.1727 29.9858V94.43C40.1727 96.9738 42.2078 99.0089 44.7517 99.0089H83.2486C85.7924 99.0089 87.8275 96.9738 87.8275 94.43V29.9858C87.8275 27.4419 85.7924 25.4069 83.2486 25.4069H44.7517Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M81.5527 91.2078H46.4476C43.9037 91.2078 41.8687 89.1727 41.8687 86.6288V37.7869C41.8687 35.2431 43.9037 33.208 46.4476 33.208H81.5527C84.0965 33.208 86.1316 35.2431 86.1316 37.7869V86.544C86.1316 89.0879 84.0965 91.2078 81.5527 91.2078ZM46.4476 36.2606C45.5996 36.2606 44.9213 36.939 44.9213 37.7869V86.544C44.9213 87.392 45.5996 88.0703 46.4476 88.0703H81.5527C82.4006 88.0703 83.079 87.392 83.079 86.544V37.7869C83.079 36.939 82.4006 36.2606 81.5527 36.2606H46.4476Z"
        fill="#7799CC"
      />
      <path className="path" d="M69.4271 93.7515H58.4885V96.8041H69.4271V93.7515Z" fill="#7799CC" />
      <path
        className="path"
        d="M66.3743 71.1962C65.1024 69.9243 62.9825 69.9243 61.6258 71.1962L59.4211 68.9915C61.8802 66.5325 65.9504 66.5325 68.4942 68.9915L66.3743 71.1962Z"
        fill="#A591D7"
      />
      <path
        className="path"
        d="M71.3774 66.1933C67.3073 62.1231 60.6933 62.1231 56.6231 66.1933L54.4185 63.9886C59.6757 58.7313 68.24 58.7313 73.4973 63.9886L71.3774 66.1933Z"
        fill="#A591D7"
      />
      <path
        className="path"
        d="M51.4505 61.0208L49.2458 58.8162C57.3862 50.6759 70.6142 50.6759 78.7545 58.8162L76.5498 61.0208C69.6814 54.0677 58.3189 54.0677 51.4505 61.0208Z"
        fill="#A591D7"
      />
    </svg>
  );
};
