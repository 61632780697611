import React from "react";
import { Link } from "react-router-dom";
import "./style.css";

export const BlogCard = ({
  link,
  imgsrc,
  date,
  readTime,
  title,
  profileImg,
  authorName,
  tag,
}) => {
  return (
    <div className="article-item">
      <Link to={link} style={{ width: "100%" }}>
        <div className="article-contents">
          <div className="article-img-wrapper">
            <img
              className="article-img"
              src={imgsrc}
              alt="Trailblu Article Image"
            />
          </div>
          <div className="card-content">
            <div className="date">
              <div>{date}</div>

              <div className="divider"></div>
              <div className="read-time">
                <div>{readTime}</div>
                <div className="min-read-txt">min read</div>
              </div>
            </div>
            <div className="title">
              <h2 className="title-text">{title}</h2>
            </div>
            <div className="card-bottom">
              <div className="author-2">
                {/* <div className="author-img">
                  <img
                    className="image"
                    src={profileImg}
                    alt="Trailblu Profile Image"
                  />
                </div> */}
                {/* <div className="author-name">{authorName}</div> */}
              </div>
              <div className="tag">
                <div>{tag}</div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
