import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../Button/Button";
import { InputLabel } from "../../components/InputLabel";
import ReCAPTCHA from "react-google-recaptcha";
import "./form.css";
import Axios from "axios";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";
import Logo from "../../assets/Logo.png";

export const Form = () => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [submitted, setSubmitted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [SuccessMsg, setSuccessMsg] = useState("");
  const [ErrorMsg, setErrorMsg] = useState("");
  const [valid_token, setValidToken] = useState([]);
  const captchaRef = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    jobtitle: "",
    companyweb: "",
    phone: "",
    message: "",
    // Add other form fields here
  });

  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const submitForm = useCallback(async () => {
    try {
      // Perform reCAPTCHA verification
      if (!executeRecaptcha) {
        console.log("Execute recaptcha not yet available");
        return;
      }

      const recaptchaToken = await executeRecaptcha("formSubmission");

      // Include the recaptcha token in your form data
      const updatedFormData = {
        ...formData,
        recaptcha_token: recaptchaToken,
      };

      // Perform form submission

      const url = "https://api-staging.trailblu.com/users/sendform";

      axios(url, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: updatedFormData,
      })
        .then((response) => {
          console.log("then", response.data);
          navigate("/thank-you/submission");
          return response.data;
        })
        .catch((error) => {
          console.log("then2", response.data);

          throw error;
        });

      setSubmitted(true);

      setTimeout(() => {
        setSubmitted(false);
        navigate("/thank-you/submission");
      }, 3000);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  });

  return (
    <div className="frame-12-form">
      <a href="/">
        <img src={Logo} className="logo-style" />
      </a>
      <div className="frame-22" id="section7">
        <div className="frame-23">
          <div className="frame-24">
            {/** <div className="h1-form">
              Request a <span className="primary-color-form"> Demo </span>
            </div>*/}
            <p className="text-wrapper-14-form">
              Schedule a product demo with expert Q&A
            </p>
          </div>
          {/*onSubmit={handleSubmit}*/}

          <form
            className="frame-25"
            id="section8"
            action="https://api.trailblu.com/users/sendform"
            method="POST"
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            <div className="frame-28">
              <InputLabel
                className="input-label-4"
                inputPropertyDefaultClassName="design-component-instance-node-2"
                placeholderText="E.g: John Johnson"
                text="Name"
                type="name"
                name="name"
                id="name"
                minlength="3"
                value={formData.name}
                onChange={handleInputChange}
              />
              <InputLabel
                className="input-label-4"
                inputPropertyDefaultClassName="design-component-instance-node-2"
                placeholderText="E.g: john@tesla.com"
                text="Business email address"
                type="email"
                name="email"
                id="email"
                minlength="3"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>{" "}
            {/*end of 28 */}
            <div className="frame-28">
              <InputLabel
                className="input-label-4"
                inputPropertyDefaultClassName="design-component-instance-node-2"
                placeholderText="E.g: Tesla"
                text="Company name"
                minlength="3"
                type="text"
                name="company"
                id="company"
                value={formData.company}
                onChange={handleInputChange}
              />
              <InputLabel
                className="input-label-4"
                inputPropertyDefaultClassName="design-component-instance-node-2"
                placeholderText="E.g: Vice president IT"
                text="Job title"
                minlength="3"
                type="text"
                name="jobtitle"
                id="jobtitle"
                value={formData.jobtitle}
                onChange={handleInputChange}
              />
            </div>
            {/*end of 28 */}
            <div className="frame-28">
              <InputLabel
                className="input-label-4"
                inputPropertyDefaultClassName="design-component-instance-node-2"
                placeholderText="E.g: tesla.com"
                text="Company website"
                minlength="4"
                type="text"
                name="companyweb"
                id="companyweb"
                value={formData.companyweb}
                onChange={handleInputChange}
              />
              <InputLabel
                className="input-label-4"
                inputPropertyDefaultClassName="design-component-instance-node-2"
                placeholderText="E.g: 05xxxxxxxxx"
                text="Phone number"
                name="phone"
                type="text"
                minlength="8"
                id="phone"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-label-wrapper">
              <InputLabel
                className="design-component-instance-node-3"
                inputPlaceholderClassName="input-label-3"
                inputPropertyDefaultClassName="input-label-2"
                placeholderText="Write your message here..."
                text="Your message"
                minlength="5"
                name="message"
                id="message"
                value={formData.message}
                onChange={handleInputChange}
                style={{ height: "100px" }}
              />
            </div>
            <div className="form-footer-has-text-centered-mt-10">
              <Button
                className="button-submit"
                stateProp="normal"
                style="solid"
                text={submitted ? "Submitting..." : "Request a demo"}
                type="regular"
                id="submitbutton"
                disabled={submitted}
              />

              <p id="messageContainer">{message}</p>
            </div>
            {/* Success message pop-up */}
          </form>
        </div>
      </div>
    </div>
  );
};
