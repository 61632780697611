/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const IconsHouse1 = ({ className }) => {
  return (
    <svg
      className={`icons-house1 ${className}`}
      fill="none"
      height="128"
      viewBox="0 0 128 128"
      width="128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M121.984 115.379H6.1951C6.0927 115.379 6.0415 115.174 6.0415 114.918C6.0415 114.662 6.1183 114.458 6.1951 114.458H121.984"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M73.6 14.3872C72.064 14.3872 70.5536 14.8992 69.3248 15.8208C67.6352 13.7984 65.1008 12.5952 62.464 12.5952C57.5488 12.5952 53.5552 16.5888 53.5552 21.504C53.5552 23.0912 54.8352 24.3712 56.4224 24.3712H77.8752C79.4624 24.3712 80.7424 23.0912 80.7424 21.504C80.7424 17.5872 77.5424 14.3872 73.6 14.3872ZM77.8752 23.4496H56.4224C55.3472 23.4496 54.4768 22.5792 54.4768 21.504C54.4768 17.1008 58.0608 13.5168 62.464 13.5168C65.024 13.5168 67.3536 14.6944 68.8896 16.7424C68.9664 16.8448 69.0688 16.9216 69.1968 16.9216C69.3248 16.9472 69.4528 16.896 69.5552 16.8192C70.6816 15.8464 72.1408 15.3088 73.6256 15.3088C77.056 15.3088 79.8464 18.0992 79.8464 21.5296C79.8208 22.5792 78.9504 23.4496 77.8752 23.4496Z"
        fill="#7799CC"
      />
      <path className="path" d="M104.704 96.2817H63.5134V96.7169H104.704V96.2817Z" fill="white" />
      <path className="path" d="M104.704 73.9841H63.5134V74.4193H104.704V73.9841Z" fill="white" />
      <path className="path" d="M100.557 104.499H99.4302V106.01H100.557V104.499Z" fill="white" />
      <path className="path" d="M100.557 100.019H99.4302V101.299H100.557V100.019Z" fill="white" />
      <path className="path" d="M100.531 88.3457H97.3311V91.1873H100.531V88.3457Z" fill="white" />
      <path className="path" d="M100.557 66.1248H99.4302V67.6352H100.557V66.1248Z" fill="white" />
      <path className="path" d="M100.557 61.6704H99.4302V62.9504H100.557V61.6704Z" fill="white" />
      <path className="path" d="M100.557 63.7695H99.4302V65.2799H100.557V63.7695Z" fill="white" />
      <path className="path" d="M100.557 68.48H99.4302V69.76H100.557V68.48Z" fill="white" />
      <path className="path" d="M100.557 102.118H99.4302V103.629H100.557V102.118Z" fill="white" />
      <path className="path" d="M100.557 106.854H99.4302V108.134H100.557V106.854Z" fill="white" />
      <path className="path" d="M98.5854 106.854H97.2798V108.134H98.5854V106.854Z" fill="white" />
      <path className="path" d="M98.5854 104.499H97.2798V106.01H98.5854V104.499Z" fill="white" />
      <path className="path" d="M98.5854 102.118H97.2798V103.629H98.5854V102.118Z" fill="white" />
      <path className="path" d="M98.5854 100.019H97.2798V101.299H98.5854V100.019Z" fill="white" />
      <path className="path" d="M98.5854 68.48H97.2798V69.76H98.5854V68.48Z" fill="white" />
      <path className="path" d="M98.5854 66.1248H97.2798V67.6352H98.5854V66.1248Z" fill="white" />
      <path className="path" d="M98.5854 63.7695H97.2798V65.2799H98.5854V63.7695Z" fill="white" />
      <path className="path" d="M98.5854 61.6704H97.2798V62.9504H98.5854V61.6704Z" fill="white" />
      <path className="path" d="M96.4608 106.854H95.104V108.134H96.4608V106.854Z" fill="white" />
      <path className="path" d="M96.4608 104.499H95.104V106.01H96.4608V104.499Z" fill="white" />
      <path className="path" d="M96.4608 102.118H95.104V103.629H96.4608V102.118Z" fill="white" />
      <path className="path" d="M96.4608 100.019H95.104V101.299H96.4608V100.019Z" fill="white" />
      <path className="path" d="M96.4608 68.48H95.104V69.76H96.4608V68.48Z" fill="white" />
      <path className="path" d="M96.4608 66.1248H95.104V67.6352H96.4608V66.1248Z" fill="white" />
      <path className="path" d="M96.4608 63.7695H95.104V65.2799H96.4608V63.7695Z" fill="white" />
      <path className="path" d="M96.4608 61.6704H95.104V62.9504H96.4608V61.6704Z" fill="white" />
      <path className="path" d="M94.259 106.854H93.1838V108.134H94.259V106.854Z" fill="white" />
      <path className="path" d="M94.259 104.499H93.1838V106.01H94.259V104.499Z" fill="white" />
      <path className="path" d="M94.259 102.118H93.1838V103.629H94.259V102.118Z" fill="white" />
      <path className="path" d="M94.259 100.019H93.1838V101.299H94.259V100.019Z" fill="white" />
      <path className="path" d="M94.259 68.48H93.1838V69.76H94.259V68.48Z" fill="white" />
      <path className="path" d="M94.259 66.1248H93.1838V67.6352H94.259V66.1248Z" fill="white" />
      <path className="path" d="M94.259 63.7695H93.1838V65.2799H94.259V63.7695Z" fill="white" />
      <path className="path" d="M94.259 61.6704H93.1838V62.9504H94.259V61.6704Z" fill="white" />
      <path
        className="path"
        d="M75.4431 36.736V39.2704H93.0303V36.736C92.0063 36.6336 91.0591 36.1984 90.3167 35.456C89.4719 34.6112 89.0111 33.5104 89.0111 32.3072V29.696H79.4367V32.3072C79.4623 34.6368 77.6959 36.5312 75.4431 36.736ZM84.2495 31.5648C86.0415 31.5648 87.5007 33.024 87.5007 34.816C87.5007 36.608 86.0415 38.0672 84.2495 38.0672C82.4575 38.0672 80.9983 36.608 80.9983 34.816C80.9983 33.024 82.4575 31.5648 84.2495 31.5648Z"
        fill="white"
      />
      <path className="path" d="M96.4862 88.3457H93.1838V91.1873H96.4862V88.3457Z" fill="white" />
      <path className="path" d="M101.504 70.6047H92.2368V70.8351H101.504V70.6047Z" fill="white" />
      <path className="path" d="M101.504 92.0063H92.2368V92.2367H101.504V92.0063Z" fill="white" />
      <path className="path" d="M101.504 108.954H92.2368V109.184H101.504V108.954Z" fill="white" />
      <path
        className="path"
        d="M104.704 114.15V97.5615H63.5134V114.15H66.8926V99.6095C66.8926 99.3791 67.0718 99.1999 67.3022 99.1999H75.315C75.5454 99.1999 75.7246 99.3791 75.7246 99.6095V114.15H104.704ZM91.3918 108.544C91.3918 108.314 91.571 108.134 91.8014 108.134H92.339V106.496C92.339 106.47 92.339 106.47 92.339 106.445C92.339 106.419 92.339 106.419 92.339 106.394V101.786C92.339 101.76 92.339 101.76 92.339 101.734C92.339 101.709 92.339 101.709 92.339 101.683V99.6351C92.339 99.4047 92.5182 99.2255 92.7486 99.2255H100.941C101.171 99.2255 101.35 99.4047 101.35 99.6351V108.16H101.888C102.118 108.16 102.297 108.339 102.297 108.57V109.619C102.297 109.85 102.118 110.029 101.888 110.029H91.7758C91.5454 110.029 91.3662 109.85 91.3662 109.619V108.544H91.3918ZM78.643 108.544C78.643 108.314 78.8222 108.134 79.0526 108.134H79.5902V106.496C79.5902 106.47 79.5902 106.47 79.5902 106.445C79.5902 106.419 79.5902 106.419 79.5902 106.394V101.786C79.5902 101.76 79.5902 101.76 79.5902 101.734C79.5902 101.709 79.5902 101.709 79.5902 101.683V99.6351C79.5902 99.4047 79.7694 99.2255 79.9998 99.2255H88.1918C88.4222 99.2255 88.6014 99.4047 88.6014 99.6351V108.16H89.139C89.3694 108.16 89.5486 108.339 89.5486 108.57V109.619C89.5486 109.85 89.3694 110.029 89.139 110.029H79.027C78.7966 110.029 78.6174 109.85 78.6174 109.619V108.544H78.643Z"
        fill="white"
      />
      <path
        className="path"
        d="M63.5134 75.2639V95.4367H104.704V75.2639H63.5134ZM76.7742 92.6719C76.7742 92.9023 76.595 93.0815 76.3646 93.0815H66.2526C66.0222 93.0815 65.843 92.9023 65.843 92.6719V91.6223C65.843 91.3919 66.0222 91.2127 66.2526 91.2127H66.7902V77.4911C66.7902 77.2607 66.9694 77.0815 67.1998 77.0815H75.3918C75.6222 77.0815 75.8014 77.2607 75.8014 77.4911V91.2127H76.339C76.5694 91.2127 76.7486 91.3919 76.7486 91.6223V92.6719H76.7742ZM89.5742 92.6719C89.5742 92.9023 89.395 93.0815 89.1646 93.0815H79.0526C78.8222 93.0815 78.643 92.9023 78.643 92.6719V91.6223C78.643 91.3919 78.8222 91.2127 79.0526 91.2127H79.5902V77.4911C79.5902 77.2607 79.7694 77.0815 79.9998 77.0815H88.1918C88.4222 77.0815 88.6014 77.2607 88.6014 77.4911V91.2127H89.139C89.3694 91.2127 89.5486 91.3919 89.5486 91.6223V92.6719H89.5742ZM101.913 93.0815H91.8014C91.571 93.0815 91.3918 92.9023 91.3918 92.6719V91.6223C91.3918 91.3919 91.571 91.2127 91.8014 91.2127H92.339V77.4911C92.339 77.2607 92.5182 77.0815 92.7486 77.0815H100.941C101.171 77.0815 101.35 77.2607 101.35 77.4911V91.2127H101.888C102.118 91.2127 102.297 91.3919 102.297 91.6223V92.6719C102.349 92.9023 102.144 93.0815 101.913 93.0815Z"
        fill="white"
      />
      <path
        className="path"
        d="M63.5134 73.1393H104.704V57.5745H63.5134V73.1393ZM91.3918 70.1697C91.3918 69.9393 91.571 69.7601 91.8014 69.7601H92.339V68.1217C92.339 68.0961 92.339 68.0961 92.339 68.0705C92.339 68.0449 92.339 68.0449 92.339 68.0193V63.4113C92.339 63.3857 92.339 63.3857 92.339 63.3601C92.339 63.3345 92.339 63.3345 92.339 63.3089V61.2609C92.339 61.0305 92.5182 60.8513 92.7486 60.8513H100.941C101.171 60.8513 101.35 61.0305 101.35 61.2609V69.7857H101.888C102.118 69.7857 102.297 69.9649 102.297 70.1953V71.2449C102.297 71.4753 102.118 71.6545 101.888 71.6545H91.7758C91.5454 71.6545 91.3662 71.4753 91.3662 71.2449V70.1697H91.3918ZM78.643 70.1697C78.643 69.9393 78.8222 69.7601 79.0526 69.7601H79.5902V68.1217C79.5902 68.0961 79.5902 68.0961 79.5902 68.0705C79.5902 68.0449 79.5902 68.0449 79.5902 68.0193V63.4113C79.5902 63.3857 79.5902 63.3857 79.5902 63.3601C79.5902 63.3345 79.5902 63.3345 79.5902 63.3089V61.2609C79.5902 61.0305 79.7694 60.8513 79.9998 60.8513H88.1918C88.4222 60.8513 88.6014 61.0305 88.6014 61.2609V69.7857H89.139C89.3694 69.7857 89.5486 69.9649 89.5486 70.1953V71.2449C89.5486 71.4753 89.3694 71.6545 89.139 71.6545H79.027C78.7966 71.6545 78.6174 71.4753 78.6174 71.2449V70.1697H78.643ZM65.843 70.1697C65.843 69.9393 66.0222 69.7601 66.2526 69.7601H66.7902V68.1217C66.7902 68.0961 66.7902 68.0961 66.7902 68.0705C66.7902 68.0449 66.7902 68.0449 66.7902 68.0193V63.4113C66.7902 63.3857 66.7902 63.3857 66.7902 63.3601C66.7902 63.3345 66.7902 63.3345 66.7902 63.3089V61.2609C66.7902 61.0305 66.9694 60.8513 67.1998 60.8513H75.3918C75.6222 60.8513 75.8014 61.0305 75.8014 61.2609V69.7857H76.339C76.5694 69.7857 76.7486 69.9649 76.7486 70.1953V71.2449C76.7486 71.4753 76.5694 71.6545 76.339 71.6545H66.227C65.9966 71.6545 65.8174 71.4753 65.8174 71.2449V70.1697H65.843Z"
        fill="white"
      />
      <path
        className="path"
        d="M78.8992 54.4H89.5744V42.4448H78.8992V54.4ZM79.5392 43.4688C79.5392 43.2384 79.7184 43.0592 79.9488 43.0592H88.5248C88.7552 43.0592 88.9344 43.2384 88.9344 43.4688V53.4016C88.9344 53.632 88.7552 53.8112 88.5248 53.8112H79.9488C79.7184 53.8112 79.5392 53.632 79.5392 53.4016V43.4688Z"
        fill="white"
      />
      <path className="path" d="M88.115 43.8784H80.3838V52.9664H88.115V43.8784Z" fill="white" />
      <path className="path" d="M87.7824 104.499H86.656V106.01H87.7824V104.499Z" fill="white" />
      <path className="path" d="M87.7824 100.019H86.656V101.299H87.7824V100.019Z" fill="white" />
      <path className="path" d="M87.7823 88.3457H84.5823V91.1873H87.7823V88.3457Z" fill="white" />
      <path className="path" d="M87.7824 66.1248H86.656V67.6352H87.7824V66.1248Z" fill="white" />
      <path className="path" d="M87.7824 61.6704H86.656V62.9504H87.7824V61.6704Z" fill="white" />
      <path className="path" d="M87.7824 63.7695H86.656V65.2799H87.7824V63.7695Z" fill="white" />
      <path className="path" d="M87.7824 68.48H86.656V69.76H87.7824V68.48Z" fill="white" />
      <path className="path" d="M87.7824 102.118H86.656V103.629H87.7824V102.118Z" fill="white" />
      <path className="path" d="M87.7824 106.854H86.656V108.134H87.7824V106.854Z" fill="white" />
      <path className="path" d="M85.8366 106.854H84.531V108.134H85.8366V106.854Z" fill="white" />
      <path className="path" d="M85.8366 104.499H84.531V106.01H85.8366V104.499Z" fill="white" />
      <path className="path" d="M85.8366 102.118H84.531V103.629H85.8366V102.118Z" fill="white" />
      <path className="path" d="M85.8366 100.019H84.531V101.299H85.8366V100.019Z" fill="white" />
      <path className="path" d="M85.8366 68.48H84.531V69.76H85.8366V68.48Z" fill="white" />
      <path className="path" d="M85.8366 66.1248H84.531V67.6352H85.8366V66.1248Z" fill="white" />
      <path className="path" d="M85.8366 63.7695H84.531V65.2799H85.8366V63.7695Z" fill="white" />
      <path className="path" d="M85.8366 61.6704H84.531V62.9504H85.8366V61.6704Z" fill="white" />
      <path className="path" d="M83.6864 106.854H82.3296V108.134H83.6864V106.854Z" fill="white" />
      <path className="path" d="M83.6864 104.499H82.3296V106.01H83.6864V104.499Z" fill="white" />
      <path className="path" d="M83.6864 102.118H82.3296V103.629H83.6864V102.118Z" fill="white" />
      <path className="path" d="M83.6864 100.019H82.3296V101.299H83.6864V100.019Z" fill="white" />
      <path className="path" d="M83.6864 68.48H82.3296V69.76H83.6864V68.48Z" fill="white" />
      <path className="path" d="M83.6864 66.1248H82.3296V67.6352H83.6864V66.1248Z" fill="white" />
      <path className="path" d="M83.6864 63.7695H82.3296V65.2799H83.6864V63.7695Z" fill="white" />
      <path className="path" d="M83.6864 61.6704H82.3296V62.9504H83.6864V61.6704Z" fill="white" />
      <path className="path" d="M81.5103 106.854H80.4351V108.134H81.5103V106.854Z" fill="white" />
      <path className="path" d="M81.5103 104.499H80.4351V106.01H81.5103V104.499Z" fill="white" />
      <path className="path" d="M81.5103 102.118H80.4351V103.629H81.5103V102.118Z" fill="white" />
      <path className="path" d="M81.5103 100.019H80.4351V101.299H81.5103V100.019Z" fill="white" />
      <path className="path" d="M81.5103 68.48H80.4351V69.76H81.5103V68.48Z" fill="white" />
      <path className="path" d="M81.5103 66.1248H80.4351V67.6352H81.5103V66.1248Z" fill="white" />
      <path className="path" d="M81.5103 63.7695H80.4351V65.2799H81.5103V63.7695Z" fill="white" />
      <path className="path" d="M81.5103 61.6704H80.4351V62.9504H81.5103V61.6704Z" fill="white" />
      <path className="path" d="M83.7375 88.3457H80.4351V91.1873H83.7375V88.3457Z" fill="white" />
      <path className="path" d="M88.7296 70.6047H79.4624V70.8351H88.7296V70.6047Z" fill="white" />
      <path className="path" d="M88.7296 92.0063H79.4624V92.2367H88.7296V92.0063Z" fill="white" />
      <path className="path" d="M88.7296 108.954H79.4624V109.184H88.7296V108.954Z" fill="white" />
      <path className="path" d="M74.9822 88.3457H71.7822V91.1873H74.9822V88.3457Z" fill="white" />
      <path className="path" d="M75.008 66.1248H73.8816V67.6352H75.008V66.1248Z" fill="white" />
      <path className="path" d="M75.008 61.6704H73.8816V62.9504H75.008V61.6704Z" fill="white" />
      <path className="path" d="M75.008 63.7695H73.8816V65.2799H75.008V63.7695Z" fill="white" />
      <path className="path" d="M75.008 68.48H73.8816V69.76H75.008V68.48Z" fill="white" />
      <path
        className="path"
        d="M73.8047 101.222H68.8127V114.15H73.8047V101.222ZM73.4463 108.877C73.4463 109.107 73.2671 109.286 73.0367 109.286C72.8063 109.286 72.6272 109.107 72.6272 108.877V107.085C72.6272 106.854 72.8063 106.675 73.0367 106.675C73.2671 106.675 73.4463 106.854 73.4463 107.085V108.877Z"
        fill="white"
      />
      <path className="path" d="M73.0368 68.48H71.7312V69.76H73.0368V68.48Z" fill="white" />
      <path className="path" d="M73.0368 66.1248H71.7312V67.6352H73.0368V66.1248Z" fill="white" />
      <path className="path" d="M73.0368 63.7695H71.7312V65.2799H73.0368V63.7695Z" fill="white" />
      <path className="path" d="M73.0368 61.6704H71.7312V62.9504H73.0368V61.6704Z" fill="white" />
      <path className="path" d="M70.912 68.48H69.5552V69.76H70.912V68.48Z" fill="white" />
      <path className="path" d="M70.912 66.1248H69.5552V67.6352H70.912V66.1248Z" fill="white" />
      <path className="path" d="M70.912 63.7695H69.5552V65.2799H70.912V63.7695Z" fill="white" />
      <path className="path" d="M70.912 61.6704H69.5552V62.9504H70.912V61.6704Z" fill="white" />
      <path className="path" d="M68.7102 68.48H67.635V69.76H68.7102V68.48Z" fill="white" />
      <path className="path" d="M68.7102 66.1248H67.635V67.6352H68.7102V66.1248Z" fill="white" />
      <path className="path" d="M68.7102 63.7695H67.635V65.2799H68.7102V63.7695Z" fill="white" />
      <path className="path" d="M68.7102 61.6704H67.635V62.9504H68.7102V61.6704Z" fill="white" />
      <path
        className="path"
        d="M74.9055 114.15V100.019H67.7119V114.15H67.9679V100.813C67.9679 100.582 68.1471 100.403 68.3775 100.403H74.2399C74.4703 100.403 74.6495 100.582 74.6495 100.813V114.15H74.9055Z"
        fill="white"
      />
      <path className="path" d="M70.9374 88.3457H67.635V91.1873H70.9374V88.3457Z" fill="white" />
      <path className="path" d="M75.9552 70.6047H66.688V70.8351H75.9552V70.6047Z" fill="white" />
      <path className="path" d="M75.9552 92.0063H66.688V92.2367H75.9552V92.0063Z" fill="white" />
      <path
        className="path"
        d="M105.805 57.1648C105.805 56.9344 105.625 56.7552 105.395 56.7552C105.318 56.7552 105.241 56.7552 105.139 56.7552C100.019 56.6272 95.9998 52.48 95.9998 47.36V39.7056C95.9998 39.4752 95.8206 39.296 95.5902 39.296H93.9006V36.352C93.9006 36.1216 93.7214 35.9424 93.491 35.9424C92.5438 35.9424 91.6222 35.5584 90.9566 34.8928C90.2654 34.2016 89.907 33.3056 89.907 32.3584V29.3376C89.907 29.1072 89.7278 28.928 89.4974 28.928H79.1038C78.8734 28.928 78.6942 29.1072 78.6942 29.3376V32.3584C78.6942 34.3296 77.0814 35.9424 75.0846 35.9424C74.8542 35.9424 74.675 36.1216 74.675 36.352V39.296H72.9854C72.755 39.296 72.5758 39.4752 72.5758 39.7056V47.36C72.5758 52.5568 68.3518 56.7808 63.1806 56.7808C62.9502 56.7808 62.771 56.96 62.771 57.1904V73.5744V74.8544V95.872V97.152V114.586C62.771 114.816 62.9502 114.995 63.1806 114.995H67.4046H68.5054H74.2398H75.3406H105.139C105.369 114.995 105.549 114.816 105.549 114.586V97.152V95.872V74.8544V73.5744V57.5744C105.677 57.4976 105.805 57.344 105.805 57.1648ZM68.8126 101.222H73.8046V114.15H68.8126V101.222ZM74.2398 100.403H68.403C68.1726 100.403 67.9934 100.582 67.9934 100.813V114.15H67.7374V100.019H74.931V114.15H74.675V100.813C74.6494 100.582 74.4702 100.403 74.2398 100.403ZM75.315 99.1744H67.3022C67.0718 99.1744 66.8926 99.3536 66.8926 99.584V114.125H63.5134V97.536H104.704V114.125H75.7502V99.584C75.7502 99.3792 75.571 99.1744 75.315 99.1744ZM104.704 73.1392H63.5134V57.5744H104.704V73.1392ZM104.704 74.4192H63.5134V73.984H104.704V74.4192ZM104.704 96.7168H63.5134V96.2816H104.704V96.7168ZM63.5134 95.4368V75.264H104.704V95.4368H63.5134ZM79.4622 29.7216H89.0366V32.3328C89.0366 33.5104 89.4974 34.6368 90.3422 35.4816C91.0846 36.224 92.0318 36.6592 93.0558 36.7616V39.296H75.4686V36.7616C77.7214 36.5568 79.4878 34.6624 79.4878 32.3584V29.7216H79.4622ZM73.3438 47.3344V40.1152H95.155V47.3344C95.155 51.584 97.6638 55.1936 101.299 56.7552H67.1486C70.7838 55.168 73.3438 51.5584 73.3438 47.3344Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M84.2495 38.0417C86.0415 38.0417 87.5007 36.5825 87.5007 34.7905C87.5007 32.9985 86.0415 31.5393 84.2495 31.5393C82.4575 31.5393 80.9983 32.9985 80.9983 34.7905C80.9983 36.5825 82.4575 38.0417 84.2495 38.0417ZM84.2495 32.3841C85.5807 32.3841 86.6559 33.4593 86.6559 34.7905C86.6559 36.1217 85.5807 37.1969 84.2495 37.1969C82.9183 37.1969 81.8431 36.1217 81.8431 34.7905C81.8431 33.4849 82.9183 32.3841 84.2495 32.3841Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M90.0094 41.6001H78.4894C78.259 41.6001 78.0798 41.7793 78.0798 42.0097V54.8097C78.0798 55.0401 78.259 55.2193 78.4894 55.2193H90.0094C90.2398 55.2193 90.419 55.0401 90.419 54.8097V42.0097C90.419 41.7793 90.2398 41.6001 90.0094 41.6001ZM89.5742 54.4001H78.899V42.4449H89.5742V54.4001Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M79.9487 53.7857H88.5247C88.7551 53.7857 88.9343 53.6065 88.9343 53.3761V43.4433C88.9343 43.2129 88.7551 43.0337 88.5247 43.0337H79.9487C79.7183 43.0337 79.5391 43.2129 79.5391 43.4433V53.3761C79.5391 53.6065 79.7183 53.7857 79.9487 53.7857ZM80.3839 43.8785H88.1151V52.9665H80.3839V43.8785Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M79.0527 110.029H89.1647C89.3951 110.029 89.5743 109.85 89.5743 109.619V108.544C89.5743 108.314 89.3951 108.134 89.1647 108.134H88.6271V99.6096C88.6271 99.3792 88.4479 99.2 88.2175 99.2H80.0255C79.7951 99.2 79.6159 99.3792 79.6159 99.6096V101.658C79.6159 101.683 79.6159 101.683 79.6159 101.709C79.6159 101.734 79.6159 101.734 79.6159 101.76V106.368C79.6159 106.394 79.6159 106.394 79.6159 106.419C79.6159 106.445 79.6159 106.445 79.6159 106.47V108.109H79.0783C78.8479 108.109 78.6687 108.288 78.6687 108.518V109.568C78.6431 109.824 78.8223 110.029 79.0527 110.029ZM87.7823 101.299H86.6559V100.019H87.7823V101.299ZM84.5311 108.109V106.829H85.8367V108.109H84.5311ZM82.3295 108.109V106.829H83.6863V108.109H82.3295ZM85.8367 103.654H84.5311V102.144H85.8367V103.654ZM84.5311 106.01V104.499H85.8367V106.01H84.5311ZM86.6559 104.499H87.7823V106.01H86.6559V104.499ZM86.6559 102.118H87.7823V103.629H86.6559V102.118ZM83.6863 102.118V103.629H82.3295V102.118H83.6863ZM81.5103 103.654H80.4351V102.144H81.5103V103.654ZM82.3295 104.499H83.6863V106.01H82.3295V104.499ZM85.8367 101.299H84.5311V100.019H85.8367V101.299ZM83.6863 101.299H82.3295V100.019H83.6863V101.299ZM80.4351 104.499H81.5103V106.01H80.4351V104.499ZM86.6559 108.109V106.829H87.7823V108.109H86.6559ZM81.5103 100.019V101.299H80.4351V100.019H81.5103ZM80.4351 106.854H81.5103V108.134H80.4351V106.854ZM79.4623 108.954H88.7295V109.184H79.4623V108.954Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M91.8271 110.029H101.939C102.169 110.029 102.349 109.85 102.349 109.619V108.544C102.349 108.314 102.169 108.134 101.939 108.134H101.376V99.6096C101.376 99.3792 101.197 99.2 100.966 99.2H92.7742C92.5438 99.2 92.3646 99.3792 92.3646 99.6096V101.658C92.3646 101.683 92.3646 101.683 92.3646 101.709C92.3646 101.734 92.3646 101.734 92.3646 101.76V106.368C92.3646 106.394 92.3646 106.394 92.3646 106.419C92.3646 106.445 92.3646 106.445 92.3646 106.47V108.109H91.8271C91.5967 108.109 91.4175 108.288 91.4175 108.518V109.568C91.3919 109.824 91.5967 110.029 91.8271 110.029ZM97.2799 108.109V106.829H98.5855V108.109H97.2799ZM95.1039 108.109V106.829H96.4606V108.109H95.1039ZM100.531 103.654H99.4046V102.144H100.531V103.654ZM100.531 106.01H99.4046V104.499H100.531V106.01ZM98.5855 103.654H97.2799V102.144H98.5855V103.654ZM97.2799 106.01V104.499H98.5855V106.01H97.2799ZM96.4606 102.118V103.629H95.1039V102.118H96.4606ZM94.2591 103.654H93.1839V102.144H94.2591V103.654ZM94.2591 104.499V106.01H93.1839V104.499H94.2591ZM95.1039 104.499H96.4606V106.01H95.1039V104.499ZM100.531 101.299H99.4046V100.019H100.531V101.299ZM98.5855 101.299H97.2799V100.019H98.5855V101.299ZM96.4606 101.299H95.1039V100.019H96.4606V101.299ZM99.4302 108.109V106.829H100.557V108.109H99.4302ZM94.2591 100.019V101.299H93.1839V100.019H94.2591ZM94.2591 106.854V108.134H93.1839V106.854H94.2591ZM92.2366 108.954H101.504V109.184H92.2366V108.954Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M66.2526 71.6545H76.3646C76.595 71.6545 76.7743 71.4753 76.7743 71.2449V70.1953C76.7743 69.9649 76.595 69.7857 76.3646 69.7857H75.8271V61.2609C75.8271 61.0305 75.6479 60.8513 75.4175 60.8513H67.2255C66.9951 60.8513 66.8159 61.0305 66.8159 61.2609V63.3089C66.8159 63.3345 66.8159 63.3345 66.8159 63.3601C66.8159 63.3857 66.8159 63.3857 66.8159 63.4113V68.0193C66.8159 68.0449 66.8159 68.0449 66.8159 68.0705C66.8159 68.0961 66.8159 68.0961 66.8159 68.1217V69.7601H66.2783C66.0479 69.7601 65.8686 69.9393 65.8686 70.1697V71.2193C65.843 71.4753 66.0222 71.6545 66.2526 71.6545ZM74.9823 62.9249H73.8559V61.6449H74.9823V62.9249ZM71.7311 69.7601V68.4801H73.0367V69.7601H71.7311ZM69.5551 69.7601V68.4801H70.9119V69.7601H69.5551ZM73.0367 65.3057H71.7311V63.7953H73.0367V65.3057ZM71.7311 67.6609V66.1505H73.0367V67.6609H71.7311ZM73.8815 66.1249H75.0079V67.6353H73.8815V66.1249ZM73.8815 63.7697H75.0079V65.2801H73.8815V63.7697ZM70.8863 63.7697V65.2801H69.5295V63.7697H70.8863ZM68.7103 65.3057H67.6351V63.7953H68.7103V65.3057ZM69.5551 66.1249H70.9119V67.6353H69.5551V66.1249ZM73.0367 62.9249H71.7311V61.6449H73.0367V62.9249ZM70.8863 62.9249H69.5295V61.6449H70.8863V62.9249ZM67.6351 66.1249H68.7103V67.6353H67.6351V66.1249ZM73.8815 69.7601V68.4801H75.0079V69.7601H73.8815ZM68.7103 61.6705V62.9505H67.6351V61.6705H68.7103ZM67.6351 68.4801H68.7103V69.7601H67.6351V68.4801ZM66.6879 70.6049H75.9551V70.8353H66.6879V70.6049Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M79.0527 71.6545H89.1647C89.3951 71.6545 89.5743 71.4753 89.5743 71.2449V70.1953C89.5743 69.9649 89.3951 69.7857 89.1647 69.7857H88.6271V61.2609C88.6271 61.0305 88.4479 60.8513 88.2175 60.8513H80.0255C79.7951 60.8513 79.6159 61.0305 79.6159 61.2609V63.3089C79.6159 63.3345 79.6159 63.3345 79.6159 63.3601C79.6159 63.3857 79.6159 63.3857 79.6159 63.4113V68.0193C79.6159 68.0449 79.6159 68.0449 79.6159 68.0705C79.6159 68.0961 79.6159 68.0961 79.6159 68.1217V69.7601H79.0783C78.8479 69.7601 78.6687 69.9393 78.6687 70.1697V71.2193C78.6431 71.4753 78.8223 71.6545 79.0527 71.6545ZM87.7823 62.9249H86.6559V61.6449H87.7823V62.9249ZM84.5311 69.7601V68.4801H85.8367V69.7601H84.5311ZM82.3295 69.7601V68.4801H83.6863V69.7601H82.3295ZM85.8367 65.3057H84.5311V63.7953H85.8367V65.3057ZM84.5311 67.6609V66.1505H85.8367V67.6609H84.5311ZM86.6559 66.1249H87.7823V67.6353H86.6559V66.1249ZM86.6559 63.7697H87.7823V65.2801H86.6559V63.7697ZM83.6863 63.7697V65.2801H82.3295V63.7697H83.6863ZM81.5103 65.3057H80.4351V63.7953H81.5103V65.3057ZM82.3295 66.1249H83.6863V67.6353H82.3295V66.1249ZM85.8367 62.9249H84.5311V61.6449H85.8367V62.9249ZM83.6863 62.9249H82.3295V61.6449H83.6863V62.9249ZM80.4351 66.1249H81.5103V67.6353H80.4351V66.1249ZM86.6559 69.7601V68.4801H87.7823V69.7601H86.6559ZM81.5103 61.6705V62.9505H80.4351V61.6705H81.5103ZM80.4351 68.4801H81.5103V69.7601H80.4351V68.4801ZM79.4623 70.6049H88.7295V70.8353H79.4623V70.6049Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M91.8271 71.6545H101.939C102.169 71.6545 102.349 71.4753 102.349 71.2449V70.1953C102.349 69.9649 102.169 69.7857 101.939 69.7857H101.376V61.2609C101.376 61.0305 101.197 60.8513 100.966 60.8513H92.7742C92.5438 60.8513 92.3646 61.0305 92.3646 61.2609V63.3089C92.3646 63.3345 92.3646 63.3345 92.3646 63.3601C92.3646 63.3857 92.3646 63.3857 92.3646 63.4113V68.0193C92.3646 68.0449 92.3646 68.0449 92.3646 68.0705C92.3646 68.0961 92.3646 68.0961 92.3646 68.1217V69.7601H91.8271C91.5967 69.7601 91.4175 69.9393 91.4175 70.1697V71.2193C91.3919 71.4753 91.5967 71.6545 91.8271 71.6545ZM97.2799 69.7601V68.4801H98.5855V69.7601H97.2799ZM95.1039 69.7601V68.4801H96.4606V69.7601H95.1039ZM100.531 65.3057H99.4046V63.7953H100.531V65.3057ZM100.531 67.6609H99.4046V66.1505H100.531V67.6609ZM98.5855 65.3057H97.2799V63.7953H98.5855V65.3057ZM97.2799 67.6609V66.1505H98.5855V67.6609H97.2799ZM96.4606 63.7697V65.2801H95.1039V63.7697H96.4606ZM94.2591 65.3057H93.1839V63.7953H94.2591V65.3057ZM94.2591 66.1249V67.6353H93.1839V66.1249H94.2591ZM95.1039 66.1249H96.4606V67.6353H95.1039V66.1249ZM100.531 62.9249H99.4046V61.6449H100.531V62.9249ZM98.5855 62.9249H97.2799V61.6449H98.5855V62.9249ZM96.4606 62.9249H95.1039V61.6449H96.4606V62.9249ZM99.4302 69.7601V68.4801H100.557V69.7601H99.4302ZM94.2591 61.6705V62.9505H93.1839V61.6705H94.2591ZM94.2591 68.4801V69.7601H93.1839V68.4801H94.2591ZM92.2366 70.6049H101.504V70.8353H92.2366V70.6049Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M76.3647 91.1871H75.8271V77.4655C75.8271 77.2351 75.6479 77.0559 75.4175 77.0559H67.2255C66.9951 77.0559 66.8159 77.2351 66.8159 77.4655V91.1871H66.2783C66.0479 91.1871 65.8687 91.3663 65.8687 91.5967V92.6463C65.8687 92.8767 66.0479 93.0559 66.2783 93.0559H76.3903C76.6207 93.0559 76.7999 92.8767 76.7999 92.6463V91.5967C76.7743 91.3663 76.5951 91.1871 76.3647 91.1871ZM75.9551 92.2367H66.6879V92.0063H75.9551V92.2367ZM74.9823 87.5007H67.6351V77.8751H74.9823V87.5007ZM71.7823 91.1871V88.3455H74.9823V91.1871H71.7823ZM67.6351 88.3455H70.9375V91.1871H67.6351V88.3455Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M89.1647 91.1871H88.6271V77.4655C88.6271 77.2351 88.4479 77.0559 88.2175 77.0559H80.0255C79.7951 77.0559 79.6159 77.2351 79.6159 77.4655V91.1871H79.0783C78.8479 91.1871 78.6687 91.3663 78.6687 91.5967V92.6463C78.6687 92.8767 78.8479 93.0559 79.0783 93.0559H89.1903C89.4207 93.0559 89.5999 92.8767 89.5999 92.6463V91.5967C89.5743 91.3663 89.3951 91.1871 89.1647 91.1871ZM88.7295 92.2367H79.4623V92.0063H88.7295V92.2367ZM87.7823 87.5007H80.4351V77.8751H87.7823V87.5007ZM84.5823 91.1871V88.3455H87.7823V91.1871H84.5823ZM80.4351 88.3455H83.7375V91.1871H80.4351V88.3455Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M101.913 91.1871H101.376V77.4655C101.376 77.2351 101.197 77.0559 100.966 77.0559H92.7743C92.5439 77.0559 92.3647 77.2351 92.3647 77.4655V91.1871H91.8271C91.5967 91.1871 91.4175 91.3663 91.4175 91.5967V92.6463C91.4175 92.8767 91.5967 93.0559 91.8271 93.0559H101.939C102.169 93.0559 102.349 92.8767 102.349 92.6463V91.5967C102.349 91.3663 102.144 91.1871 101.913 91.1871ZM101.504 92.2367H92.2367V92.0063H101.504V92.2367ZM97.3311 91.1871V88.3455H100.531V91.1871H97.3311ZM100.531 87.5007H93.1839V77.8751H100.531V87.5007ZM93.1839 88.3455H96.4863V91.1871H93.1839V88.3455Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M73.0368 106.675C72.8064 106.675 72.6272 106.854 72.6272 107.085V108.877C72.6272 109.107 72.8064 109.286 73.0368 109.286C73.2672 109.286 73.4464 109.107 73.4464 108.877V107.085C73.4464 106.854 73.2672 106.675 73.0368 106.675Z"
        fill="#4195CE"
      />
      <path className="path" d="M42.7007 77.3633H40.2175V79.9745H42.7007V77.3633Z" fill="white" />
      <path className="path" d="M31.7696 67.1743H29.2864V69.4271H31.7696V67.1743Z" fill="white" />
      <path className="path" d="M31.7696 64.0769H29.2864V66.3297H31.7696V64.0769Z" fill="white" />
      <path className="path" d="M31.6927 77.3633H29.2095V79.9745H31.6927V77.3633Z" fill="white" />
      <path className="path" d="M31.6927 80.8193H29.2095V83.0721H31.6927V80.8193Z" fill="white" />
      <path
        className="path"
        d="M37.504 101.222H32.512V114.15H37.504V101.222ZM37.1456 108.877C37.1456 109.107 36.9664 109.286 36.736 109.286C36.5056 109.286 36.3264 109.107 36.3264 108.877V107.085C36.3264 106.854 36.5056 106.675 36.736 106.675C36.9664 106.675 37.1456 106.854 37.1456 107.085V108.877Z"
        fill="white"
      />
      <path
        className="path"
        d="M38.6047 114.15V100.019H31.4111V114.15H31.6671V100.813C31.6671 100.582 31.8463 100.403 32.0767 100.403H37.9135C38.1439 100.403 38.3231 100.582 38.3231 100.813V114.15H38.6047Z"
        fill="white"
      />
      <path className="path" d="M35.0208 80.8193H32.5376V83.0721H35.0208V80.8193Z" fill="white" />
      <path className="path" d="M35.0208 77.3633H32.5376V79.9745H35.0208V77.3633Z" fill="white" />
      <path className="path" d="M35.0975 60.6465H32.6143V63.2577H35.0975V60.6465Z" fill="white" />
      <path className="path" d="M35.0208 83.9167H32.5376V86.1696H35.0208V83.9167Z" fill="white" />
      <path className="path" d="M35.0208 86.9888H32.5376V89.6H35.0208V86.9888Z" fill="white" />
      <path className="path" d="M35.0975 64.0769H32.6143V66.3297H35.0975V64.0769Z" fill="white" />
      <path className="path" d="M35.0975 67.1743H32.6143V69.4271H35.0975V67.1743Z" fill="white" />
      <path className="path" d="M42.7007 83.9167H40.2175V86.1696H42.7007V83.9167Z" fill="white" />
      <path className="path" d="M35.0975 70.2463H32.6143V72.8575H35.0975V70.2463Z" fill="white" />
      <path className="path" d="M42.7007 80.8193H40.2175V83.0721H42.7007V80.8193Z" fill="white" />
      <path className="path" d="M42.7007 86.9888H40.2175V89.6H42.7007V86.9888Z" fill="white" />
      <path className="path" d="M42.752 60.6465H40.2688V63.2577H42.752V60.6465Z" fill="white" />
      <path className="path" d="M42.752 67.1743H40.2688V69.4271H42.752V67.1743Z" fill="white" />
      <path className="path" d="M42.752 64.0769H40.2688V66.3297H42.752V64.0769Z" fill="white" />
      <path className="path" d="M42.752 70.2463H40.2688V72.8575H42.752V70.2463Z" fill="white" />
      <path className="path" d="M46.0286 80.8193H43.5454V83.0721H46.0286V80.8193Z" fill="white" />
      <path className="path" d="M46.003 100.019H43.3918V102.502H46.003V100.019Z" fill="white" />
      <path className="path" d="M46.003 103.347H43.3918V105.83H46.003V103.347Z" fill="white" />
      <path className="path" d="M46.0286 77.3633H43.5454V79.9745H46.0286V77.3633Z" fill="white" />
      <path className="path" d="M46.0286 83.9167H43.5454V86.1696H46.0286V83.9167Z" fill="white" />
      <path className="path" d="M46.0799 60.6465H43.5967V63.2577H46.0799V60.6465Z" fill="white" />
      <path className="path" d="M46.0286 86.9888H43.5454V89.6H46.0286V86.9888Z" fill="white" />
      <path
        className="path"
        d="M39.9358 44.6719H46.0286C45.8238 43.1615 44.5438 42.0095 42.9822 42.0095C42.163 42.0095 41.395 42.3423 40.8062 42.9055C40.3198 43.3919 40.0382 44.0063 39.9358 44.6719Z"
        fill="white"
      />
      <path className="path" d="M46.08 45.5168H39.9104V51.0976H46.08V45.5168Z" fill="white" />
      <path className="path" d="M46.0799 64.0769H43.5967V66.3297H46.0799V64.0769Z" fill="white" />
      <path className="path" d="M46.0799 70.2463H43.5967V72.8575H46.0799V70.2463Z" fill="white" />
      <path className="path" d="M46.0799 67.1743H43.5967V69.4271H46.0799V67.1743Z" fill="white" />
      <path
        className="path"
        d="M38.3999 31.0785H47.5647C47.3599 28.7233 45.3887 26.8801 42.9823 26.8801C40.5759 26.8801 38.6047 28.7233 38.3999 31.0785Z"
        fill="white"
      />
      <path className="path" d="M49.1007 100.019H46.8479V102.502H49.1007V100.019Z" fill="white" />
      <path className="path" d="M53.5552 77.3633H51.072V79.9745H53.5552V77.3633Z" fill="white" />
      <path className="path" d="M49.1007 103.347H46.8479V105.83H49.1007V103.347Z" fill="white" />
      <path className="path" d="M52.1984 100.019H49.9456V102.502H52.1984V100.019Z" fill="white" />
      <path className="path" d="M52.1984 103.347H49.9456V105.83H52.1984V103.347Z" fill="white" />
      <path className="path" d="M53.5552 80.8193H51.072V83.0721H53.5552V80.8193Z" fill="white" />
      <path className="path" d="M53.5552 86.9888H51.072V89.6H53.5552V86.9888Z" fill="white" />
      <path className="path" d="M53.5552 83.9167H51.072V86.1696H53.5552V83.9167Z" fill="white" />
      <path className="path" d="M53.6319 60.6465H51.1487V63.2577H53.6319V60.6465Z" fill="white" />
      <path className="path" d="M53.6319 64.0769H51.1487V66.3297H53.6319V64.0769Z" fill="white" />
      <path className="path" d="M53.6319 67.1743H51.1487V69.4271H53.6319V67.1743Z" fill="white" />
      <path className="path" d="M53.6319 70.2463H51.1487V72.8575H53.6319V70.2463Z" fill="white" />
      <path className="path" d="M55.6288 100.019H53.0176V102.502H55.6288V100.019Z" fill="white" />
      <path className="path" d="M55.6288 103.347H53.0176V105.83H55.6288V103.347Z" fill="white" />
      <path className="path" d="M56.8831 77.3633H54.3999V79.9745H56.8831V77.3633Z" fill="white" />
      <path className="path" d="M56.8831 80.8193H54.3999V83.0721H56.8831V80.8193Z" fill="white" />
      <path className="path" d="M56.8831 83.9167H54.3999V86.1696H56.8831V83.9167Z" fill="white" />
      <path className="path" d="M56.8831 86.9888H54.3999V89.6H56.8831V86.9888Z" fill="white" />
      <path className="path" d="M56.96 60.6465H54.4768V63.2577H56.96V60.6465Z" fill="white" />
      <path className="path" d="M56.96 64.0769H54.4768V66.3297H56.96V64.0769Z" fill="white" />
      <path className="path" d="M56.96 67.1743H54.4768V69.4271H56.96V67.1743Z" fill="white" />
      <path className="path" d="M56.96 70.2463H54.4768V72.8575H56.96V70.2463Z" fill="white" />
      <path className="path" d="M58.4959 54.0161H27.4688V54.9889H58.4959V54.0161Z" fill="white" />
      <path
        className="path"
        d="M27.4688 55.8335V94.3871H58.4959V55.8335H27.4688ZM35.8656 90.0351C35.8656 90.2655 35.6863 90.4447 35.4559 90.4447H28.7999C28.5695 90.4447 28.3903 90.2655 28.3903 90.0351V76.9535C28.3903 76.7231 28.5695 76.5439 28.7999 76.5439H35.4559C35.6863 76.5439 35.8656 76.7231 35.8656 76.9535V90.0351ZM35.9167 73.2927C35.9167 73.5231 35.7375 73.7023 35.5071 73.7023H28.8511C28.6207 73.7023 28.4415 73.5231 28.4415 73.2927V60.2111C28.4415 59.9807 28.6207 59.8015 28.8511 59.8015H35.5071C35.7375 59.8015 35.9167 59.9807 35.9167 60.2111V73.2927ZM46.8735 90.0351C46.8735 90.2655 46.6943 90.4447 46.4639 90.4447H39.8079C39.5775 90.4447 39.3983 90.2655 39.3983 90.0351V76.9535C39.3983 76.7231 39.5775 76.5439 39.8079 76.5439H46.4639C46.6943 76.5439 46.8735 76.7231 46.8735 76.9535V90.0351ZM46.9247 73.2927C46.9247 73.5231 46.7455 73.7023 46.5151 73.7023H39.8591C39.6287 73.7023 39.4495 73.5231 39.4495 73.2927V60.2111C39.4495 59.9807 39.6287 59.8015 39.8591 59.8015H46.5151C46.7455 59.8015 46.9247 59.9807 46.9247 60.2111V73.2927ZM57.7279 90.0351C57.7279 90.2655 57.5487 90.4447 57.3183 90.4447H50.6623C50.4319 90.4447 50.2527 90.2655 50.2527 90.0351V76.9535C50.2527 76.7231 50.4319 76.5439 50.6623 76.5439H57.3183C57.5487 76.5439 57.7279 76.7231 57.7279 76.9535V90.0351ZM57.8047 73.2927C57.8047 73.5231 57.6255 73.7023 57.3951 73.7023H50.7391C50.5087 73.7023 50.3295 73.5231 50.3295 73.2927V60.2111C50.3295 59.9807 50.5087 59.8015 50.7391 59.8015H57.3951C57.6255 59.8015 57.8047 59.9807 57.8047 60.2111V73.2927Z"
        fill="white"
      />
      <path className="path" d="M58.4959 95.2319H27.4688V96.3583H58.4959V95.2319Z" fill="white" />
      <path
        className="path"
        d="M27.4688 97.1777V114.151H30.5663V99.6097C30.5663 99.3793 30.7455 99.2001 30.9759 99.2001H38.9888C39.2192 99.2001 39.3983 99.3793 39.3983 99.6097V114.151H58.4703V97.1777H27.4688ZM56.4736 106.24C56.4736 106.471 56.2943 106.65 56.064 106.65H42.9823C42.7519 106.65 42.5728 106.471 42.5728 106.24V99.5841C42.5728 99.3537 42.7519 99.1745 42.9823 99.1745H56.064C56.2943 99.1745 56.4736 99.3537 56.4736 99.5841V106.24Z"
        fill="white"
      />
      <path className="path" d="M31.6927 83.9167H29.2095V86.1696H31.6927V83.9167Z" fill="white" />
      <path className="path" d="M31.7696 60.6465H29.2864V63.2577H31.7696V60.6465Z" fill="white" />
      <path className="path" d="M31.7696 70.2463H29.2864V72.8575H31.7696V70.2463Z" fill="white" />
      <path className="path" d="M31.6927 86.9888H29.2095V89.6H31.6927V86.9888Z" fill="white" />
      <path
        className="path"
        d="M40.9856 36.48C40.9856 37.5808 41.8816 38.4768 42.9824 38.4768C44.0832 38.4768 44.9792 37.5808 44.9792 36.48C44.9792 35.3792 44.0832 34.4832 42.9824 34.4832C41.8816 34.4832 40.9856 35.3792 40.9856 36.48Z"
        fill="white"
      />
      <path
        className="path"
        d="M58.9056 53.1712C53.12 53.1712 48.4096 48.4608 48.4096 42.6752V31.872H50.2528C50.4832 31.872 50.6624 31.6928 50.6624 31.4624C50.6624 31.232 50.4832 31.0528 50.2528 31.0528H48.384C48.1792 28.2368 45.824 26.0352 42.9568 26.0352C40.0896 26.0352 37.76 28.2624 37.5296 31.0528H35.6608C35.4304 31.0528 35.2512 31.232 35.2512 31.4624C35.2512 31.6928 35.4304 31.872 35.6608 31.872H37.504V42.6752C37.504 48.4608 32.7936 53.1712 27.008 53.1712C26.7776 53.1712 26.5984 53.3504 26.5984 53.5808V94.7967V96.768V114.56C26.5984 114.79 26.7776 114.97 27.008 114.97H30.9504H32.0512H37.888H38.9888H58.88C59.1104 114.97 59.2896 114.79 59.2896 114.56V96.768V94.7967V53.5808C59.3408 53.376 59.136 53.1712 58.9056 53.1712ZM32.512 101.222H37.504V114.15H32.512V101.222ZM37.9136 100.403H32.0768C31.8464 100.403 31.6672 100.582 31.6672 100.813V114.15H31.4112V100.019H38.6048V114.15H38.3488V100.813C38.3488 100.582 38.144 100.403 37.9136 100.403ZM39.424 114.15V99.6096C39.424 99.3792 39.2448 99.2 39.0144 99.2H31.0016C30.7712 99.2 30.592 99.3792 30.592 99.6096V114.15H27.4944V97.1776H58.5216V114.15H39.424ZM58.496 54.9888H27.4688V54.016H58.496V54.9888ZM58.496 95.2319V96.3584H27.4688V95.2319H58.496ZM27.4688 94.3872V55.8336H58.496V94.3872H27.4688ZM47.5648 31.0784H38.4C38.6048 28.7232 40.576 26.88 42.9824 26.88C45.3888 26.88 47.36 28.7232 47.5648 31.0784ZM38.3744 42.7008V31.8976H47.5904V42.7008C47.5904 47.4368 50.5088 51.5072 54.656 53.1968H31.3344C35.456 51.4816 38.3744 47.4368 38.3744 42.7008Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M42.9823 39.2961C44.5439 39.2961 45.7983 38.0161 45.7983 36.4801C45.7983 34.9185 44.5183 33.6641 42.9823 33.6641C41.4207 33.6641 40.1663 34.9441 40.1663 36.4801C40.1663 38.0161 41.4207 39.2961 42.9823 39.2961ZM44.9791 36.4801C44.9791 37.5809 44.0831 38.4769 42.9823 38.4769C41.8815 38.4769 40.9855 37.5809 40.9855 36.4801C40.9855 35.3793 41.8815 34.4833 42.9823 34.4833C44.0831 34.4833 44.9791 35.3793 44.9791 36.4801Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M42.9822 41.1648C41.9326 41.1648 40.9598 41.5744 40.2174 42.3168C39.475 43.0592 39.0654 44.032 39.0654 45.0816V51.5072C39.0654 51.7376 39.2446 51.9168 39.475 51.9168H46.4638C46.6942 51.9168 46.8734 51.7376 46.8734 51.5072V45.0816C46.899 42.9312 45.1326 41.1648 42.9822 41.1648ZM46.0286 44.672H39.9358C40.0382 44.0064 40.3198 43.392 40.8062 42.9056C41.395 42.3168 42.163 42.0096 42.9822 42.0096C44.5438 42.0096 45.8238 43.1616 46.0286 44.672ZM39.9102 45.5168H46.0798V51.0976H39.9102V45.5168Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M35.4303 76.5439H28.7743C28.5439 76.5439 28.3647 76.7231 28.3647 76.9535V90.0351C28.3647 90.2655 28.5439 90.4447 28.7743 90.4447H35.4303C35.6607 90.4447 35.8399 90.2655 35.8399 90.0351V76.9535C35.8655 76.7231 35.6607 76.5439 35.4303 76.5439ZM35.0207 77.3631V79.9743H32.5375V77.3631H35.0207ZM31.6927 83.9167V86.1695H29.2095V83.9167H31.6927ZM32.5375 83.9167H35.0207V86.1695H32.5375V83.9167ZM32.5375 80.8191H35.0207V83.0719H32.5375V80.8191ZM31.6927 80.8191V83.0719H29.2095V80.8191H31.6927ZM32.5375 86.9887H35.0207V89.5999H32.5375V86.9887ZM31.6927 77.3631V79.9743H29.2095V77.3631H31.6927ZM29.2095 86.9887H31.6927V89.5999H29.2095V86.9887Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M46.4384 76.5439H39.7824C39.552 76.5439 39.3728 76.7231 39.3728 76.9535V90.0351C39.3728 90.2655 39.552 90.4447 39.7824 90.4447H46.4384C46.6688 90.4447 46.848 90.2655 46.848 90.0351V76.9535C46.8736 76.7231 46.6688 76.5439 46.4384 76.5439ZM40.2176 86.9887H42.7008V89.5999H40.2176V86.9887ZM42.7008 86.1695H40.2176V83.9167H42.7008V86.1695ZM42.7008 83.0719H40.2176V80.8191H42.7008V83.0719ZM42.7008 79.9743H40.2176V77.3631H42.7008V79.9743ZM43.5456 83.9167H46.0288V86.1695H43.5456V83.9167ZM43.5456 83.0719V80.8191H46.0288V83.0719H43.5456ZM46.0288 79.9743H43.5456V77.3631H46.0288V79.9743ZM43.5456 89.5999V86.9887H46.0288V89.5999H43.5456Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M57.3183 76.5439H50.6623C50.4319 76.5439 50.2527 76.7231 50.2527 76.9535V90.0351C50.2527 90.2655 50.4319 90.4447 50.6623 90.4447H57.3183C57.5487 90.4447 57.7279 90.2655 57.7279 90.0351V76.9535C57.7279 76.7231 57.5487 76.5439 57.3183 76.5439ZM56.9087 77.3631V79.9743H54.4255V77.3631H56.9087ZM53.5807 83.9167V86.1695H51.0975V83.9167H53.5807ZM54.3999 83.9167H56.8831V86.1695H54.3999V83.9167ZM54.3999 80.8191H56.8831V83.0719H54.3999V80.8191ZM53.5807 80.8191V83.0719H51.0975V80.8191H53.5807ZM54.3999 86.9887H56.8831V89.5999H54.3999V86.9887ZM53.5807 77.3631V79.9743H51.0975V77.3631H53.5807ZM51.0719 86.9887H53.5551V89.5999H51.0719V86.9887Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M35.507 59.8015H28.851C28.6206 59.8015 28.4414 59.9807 28.4414 60.2111V73.2927C28.4414 73.5231 28.6206 73.7023 28.851 73.7023H35.507C35.7374 73.7023 35.9166 73.5231 35.9166 73.2927V60.2111C35.9166 59.9807 35.7374 59.8015 35.507 59.8015ZM29.2862 72.8575V70.2463H31.7694V72.8575H29.2862ZM32.6142 66.3295V64.0767H35.0974V66.3295H32.6142ZM31.7694 66.3295H29.2862V64.0767H31.7694V66.3295ZM31.7694 67.1743V69.4271H29.2862V67.1743H31.7694ZM32.6142 69.4271V67.1743H35.0974V69.4271H32.6142ZM35.0974 63.2319H32.6142V60.6207H35.0974V63.2319ZM31.7694 60.6463V63.2319H29.2862V60.6207H31.7694V60.6463ZM32.6142 72.8575V70.2463H35.0974V72.8575H32.6142Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M46.5151 59.8015H39.8591C39.6287 59.8015 39.4495 59.9807 39.4495 60.2111V73.2927C39.4495 73.5231 39.6287 73.7023 39.8591 73.7023H46.5151C46.7455 73.7023 46.9247 73.5231 46.9247 73.2927V60.2111C46.9247 59.9807 46.7455 59.8015 46.5151 59.8015ZM46.0799 63.2319H43.5967V60.6207H46.0799V63.2319ZM42.7775 67.1743V69.4271H40.2943V67.1743H42.7775ZM43.5967 67.1743H46.0799V69.4271H43.5967V67.1743ZM43.5967 64.0767H46.0799V66.3295H43.5967V64.0767ZM42.7775 64.0767V66.3295H40.2943V64.0767H42.7775ZM43.5967 70.2463H46.0799V72.8575H43.5967V70.2463ZM42.7775 60.6463V63.2319H40.2943V60.6207H42.7775V60.6463ZM40.2687 70.2463H42.7519V72.8575H40.2687V70.2463Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M57.3696 59.8015H50.7136C50.4832 59.8015 50.304 59.9807 50.304 60.2111V73.2927C50.304 73.5231 50.4832 73.7023 50.7136 73.7023H57.3696C57.6 73.7023 57.7792 73.5231 57.7792 73.2927V60.2111C57.8048 59.9807 57.6 59.8015 57.3696 59.8015ZM51.1488 72.8575V70.2463H53.632V72.8575H51.1488ZM54.4768 66.3295V64.0767H56.96V66.3295H54.4768ZM53.632 66.3295H51.1488V64.0767H53.632V66.3295ZM53.632 67.1743V69.4271H51.1488V67.1743H53.632ZM54.4768 69.4271V67.1743H56.96V69.4271H54.4768ZM56.96 63.2319H54.4768V60.6207H56.96V63.2319ZM53.632 60.6463V63.2319H51.1488V60.6207H53.632V60.6463ZM54.4768 72.8575V70.2463H56.96V72.8575H54.4768Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M56.064 99.1743H42.9824C42.752 99.1743 42.5728 99.3535 42.5728 99.5839V106.24C42.5728 106.47 42.752 106.65 42.9824 106.65H56.064C56.2944 106.65 56.4736 106.47 56.4736 106.24V99.5839C56.4736 99.3791 56.2944 99.1743 56.064 99.1743ZM55.6288 102.502H53.0176V100.019H55.6288V102.502ZM43.392 103.347H46.0032V105.83H43.392V103.347ZM52.1984 102.502H49.9456V100.019H52.1984V102.502ZM49.1008 102.502H46.848V100.019H49.1008V102.502ZM46.0032 102.502H43.392V100.019H46.0032V102.502ZM46.848 103.347H49.1008V105.83H46.848V103.347ZM49.9456 103.347H52.1984V105.83H49.9456V103.347ZM53.0176 105.83V103.347H55.6288V105.83H53.0176Z"
        fill="#7799CC"
      />
      <path
        className="path"
        d="M36.7104 106.675C36.48 106.675 36.3008 106.854 36.3008 107.085V108.877C36.3008 109.107 36.48 109.286 36.7104 109.286C36.9408 109.286 37.12 109.107 37.12 108.877V107.085C37.1456 106.854 36.9408 106.675 36.7104 106.675Z"
        fill="#7799CC"
      />
      <path className="path" d="M60.8511 80.9985L59.9807 81.8689H63.9743L63.0783 80.9985H60.8511Z" fill="white" />
      <path
        className="path"
        d="M59.9551 82.7136C60.1599 83.6608 60.9791 84.352 61.9775 84.352C62.9759 84.352 63.8207 83.6352 63.9999 82.7136H59.9551Z"
        fill="white"
      />
      <path className="path" d="M62.848 80.1023H61.1072V80.1791H62.848V80.1023Z" fill="white" />
      <path className="path" d="M54.4766 113.382H53.2734V114.15H58.2142V113.382H57.011H54.4766Z" fill="white" />
      <path className="path" d="M56.6015 103.731H54.9119V112.538H56.6015V103.731Z" fill="white" />
      <path
        className="path"
        d="M65.2799 81.9969L63.6927 80.4097V79.6673C63.6927 79.4369 63.5135 79.2577 63.2831 79.2577H62.4127V76.2625C62.4127 75.3153 62.0543 74.4193 61.3887 73.7537C60.7231 73.0881 59.8271 72.7297 58.8799 72.7297C56.9343 72.7297 55.3471 74.3169 55.3471 76.2625V78.5665H54.5279C54.2975 78.5665 54.1183 78.7457 54.1183 78.9761V79.7185C54.1183 79.9489 54.2975 80.1281 54.5279 80.1281H55.3471V102.861H54.5023C54.2719 102.861 54.0927 103.04 54.0927 103.271V112.487H52.8639C52.6335 112.487 52.4543 112.666 52.4543 112.896V114.509C52.4543 114.739 52.6335 114.919 52.8639 114.919H58.6495C58.8799 114.919 59.0591 114.739 59.0591 114.509V112.896C59.0591 112.666 58.8799 112.487 58.6495 112.487H57.4207V103.271C57.4207 103.04 57.2415 102.861 57.0111 102.861H56.1663V80.1281H56.9855C57.2159 80.1281 57.3951 79.9489 57.3951 79.7185V78.9761C57.3951 78.7457 57.2159 78.5665 56.9855 78.5665H56.1663V76.2625C56.1663 74.7777 57.3695 73.5745 58.8543 73.5745C59.5711 73.5745 60.2623 73.8561 60.7487 74.3681C61.2607 74.8801 61.5423 75.5457 61.5423 76.2881V79.2833H60.6719C60.4415 79.2833 60.2623 79.4625 60.2623 79.6929V80.4353L58.6751 82.0225C58.5471 82.1505 58.5215 82.3297 58.5727 82.4833C58.6495 82.6369 58.7775 82.7393 58.9567 82.7393H59.0847C59.2895 84.1473 60.4927 85.2225 61.9519 85.2225C63.4111 85.2225 64.6143 84.1473 64.8191 82.7393H64.9471C65.1263 82.7393 65.2799 82.6369 65.3311 82.4833C65.4335 82.3041 65.3823 82.1249 65.2799 81.9969ZM58.2143 114.151H53.2479V113.383H54.4767H56.9855H58.2143V114.151ZM56.6015 103.731V112.538H54.9119V103.731H56.6015ZM61.9775 84.3521C60.9791 84.3521 60.1343 83.6353 59.9551 82.7137H63.9999C63.7951 83.6609 62.9759 84.3521 61.9775 84.3521ZM59.9807 81.8689L60.8511 80.9985H63.0783L63.9487 81.8689H59.9807ZM62.8479 80.1025V80.1793H61.1071V80.1025H62.8479Z"
        fill="#7799CC"
      />
    </svg>
  );
};
