import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import "./style.css";
const NotFoundPage = () => {
  return (
    <div>
      <Header />
      <div className="main-not-found-page" style={{ textAlign: "center" }}>
        <h1 className="h1-not-found">Oops! Page Not Found</h1>
        <p className="p-not-found">
          The page you are looking for doesn't exist or an other error occurred.
        </p>
        <p className="go-back-home">
          <a href="/">Go back to the homepage</a>
        </p>
        {/* Update the src attribute to the path where your image is located */}
        <img
          src="https://www.trailblu.com/worker.2a57633e.png"
          alt="Page Not Found"
          style={{ maxWidth: "100%", height: "auto", width: "450px" }}
        />
      </div>
      <Footer />
    </div>
  );
};

export default NotFoundPage;
