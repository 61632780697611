import React, { useEffect } from "react";
import Header from "../../components/Header/Header";
import "./style.css"; // Import your CSS file for Blog styling
import Footer from "../../components/Footer/Footer";
import { Button } from "../../components/Button";
import Subscription from "../../components/Subscription/Subscription";
import { BlogCard } from "../../components/BlogCard/BlogCard";
import { Helmet } from "react-helmet-async";
import TrailbluIntro from "../../assets/TrailbluIntro.png";
import { createSlug, blogPosts } from "../../blogData";

export const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const firstPost = blogPosts[0];

  return (
    <div className="main-2">
      <Helmet>
        <title>Blogs, Articles, and News | TRAILBLU</title>
        <meta
          name="description"
          content="Stay ahead in testing with TRAILBLU. Explore No-Code AI-based testing insights for mobile and web applications."
        />
        <meta
          name="twitter:title"
          content="TRAILBLU Blog: AI-Driven No-Code Testing Insights"
        />
        <meta
          name="twitter:description"
          content="Dive into the world of AI-driven no-code testing with TRAILBLU's blog. Discover expert insights, trends, and innovations revolutionizing testing practices. Stay ahead with our in-depth articles."
        />
        <meta name="twitter:image" content={TrailbluIntro} />
        <meta name="twitter:image:alt" content="TRAILBLU Image" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@trailblu" />
        <meta
          property="og:title"
          content="TRAILBLU Blog: AI-Driven No-Code Testing Insights"
        />
        <meta
          property="og:description"
          content="Dive into the world of AI-driven no-code testing with TRAILBLU's blog. Discover expert insights, trends, and innovations revolutionizing testing practices. Stay ahead with our in-depth articles."
        />
        <meta property="og:image" content={TrailbluIntro} />
        <meta property="og:image:alt" content="TRAILBLU Image" />
        <meta property="og:type" content="articles" />
        <meta property="og:url" content="https://www.trailblu.com/blog" />
      </Helmet>
      <Header />
      {/* <div className="hero-section"> */}
        <div className="center">
          <div className="bottom"></div>
          <div className="company-name">
            <h1 className="h1-class">
              The
              <span className="primary-color"> Trailblu </span>
              Blog
            </h1>
          </div>
        </div>
      {/* </div> */}
      <div className="middle-section">
        <p className="h2-class">
          No-Code, AI-Powered Testing For Your Mobile and Web Applications
        </p>
        <p className="h3-class">
          Discover the future of app testing with{" "}
          <span className="primary-color-2"> Trailblu</span>'s AI-driven
          solution—seamless, code-free, and impeccably accurate.
        </p>
        <a href="/">
          <Button
            className="design-component-instance-node-2"
            stateProp="normal"
            style="solid"
            text="Learn More"
            type="regular"
          />
        </a>
      </div>
      <div className="news-section">
        <div className="news">
          <a
            href={`/blog/${createSlug(firstPost.title)}`}
            className="news-items"
          >
            <div className="news-image-wrapper">
              <img className="news-image" src={firstPost.blogImage} alt={firstPost.title} />
            </div>
            <div className="news-text">
              <div className="news-date">
                <p>{firstPost.date}</p>
                <div className="divider"></div>
                <div className="read-time">
                  <div>{firstPost.readTime}</div>
                  <div className="min-read-txt">min read</div>
                </div>
              </div>
              <div className="news-content">
                <h2 className="news-title">
                  {firstPost.title}
                </h2>
                <p className="news-desc">
                  {firstPost.metaDescription}
                </p>
              </div>
              <div className="news-bottom">
                <div className="author-2">
                  <div className="author-img">
                    <img
                      className="image"
                      src={firstPost.authorImageUrl}
                      alt="Trailblu Profile Image"
                    />
                  </div>
                  <div className="author-name">{firstPost.author}</div>
                </div>
                <div className="tag">
                  <div>{firstPost.tag}</div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div
        style={{ maxWidth: "1280px", alignSelf: "center" }}
        className="divider"
      ></div>
      <div className="section-blog">
        <div className="column-container">
          <div className="column-wrap">
            <div className="article-columns" role="list">
              {blogPosts.slice(1).map((post, index) => (
                <div className="column" role="listitem" key={index}>
                  <BlogCard
                    link={`/blog/${createSlug(post.title)}`}
                    imgsrc={post.blogImage}
                    date={post.date}
                    readTime={post.readTime}
                    title={post.title}
                    profileImg={post.authorImageUrl}
                    authorName={post.author}
                    tag={post.tag}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Subscription />
      <Footer />
    </div>
  );
};

export default Blog;
