import React from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet-async";
import Worker from "../../assets/worker.png";
import "./style.css";

const ThanksForSubmission = () => {
  return (
    <div className="thank-main">
      <Helmet></Helmet>
      <Header />
      <div className="frame-wrapper-thank">
        <div className="div-2-thank">
          <div className="div-wrapper-thank">
            <div className="frame-4-thank">
              <h1 className="text-wrapper-3-thank">
                Thank You for Your Interest in Trailblu!
              </h1>
              <p className="p-thank">
                We've received your request for a demo. Our team will be in
                touch shortly to schedule a meeting and showcase our AI-powered,
                no-code testing platform. Stay tuned!
              </p>
            </div>
          </div>
          <div className="img-wrapper-thank">
            <img
              className="worker-thank"
              alt="Trailblu Worker Image"
              src={Worker}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ThanksForSubmission;
