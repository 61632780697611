import React, { useState } from "react";
import { Button } from "../../components/Button";
import "./style.css";

export const Subscription = () => {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your subscription handling logic here using 'email' state
    console.log("Subscribed with email:", email);
    // Reset the email field after submission
    setEmail("");
    setSubscribed(true);
  };
  return (
    <div className="bottom-section">
      <p className="h1-class-3">Discover What's New in Testing</p>
      <div className="paragraph-divider"></div>
      <p className="h2-class-2">
        Subscribe now for Trailblu's AI app testing updates.
      </p>
      {!subscribed ? (
        <form onSubmit={handleSubmit} className="form-style">
          <input
            type="email"
            placeholder="Business Email"
            value={email}
            onChange={handleEmailChange}
            className="form-input-style"
            required
          />
          <Button
            className="subscribe-button"
            stateProp="normal"
            style="solid"
            text="Subscribe"
            type="regular"
            id="subscribeButton"
          />
        </form>
      ) : (
        <div className="thank-you-message">Thank you for subscribing!</div>
      )}
    </div>
  );
};

export default Subscription;
