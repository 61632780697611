/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Input } from "../Input/Input";
import "./style.css";

export const InputLabel = ({
  className,
  text = "Label",
  placeholderText,
  inputPropertyDefaultClassName,
  inputPlaceholderClassName,
  onChange,
  value,
  name,
  type,
  id,
  minlength
}) => {
  return (
    <div className={`input-label ${className}`}>
      <div className="label">{text}</div>
      <Input
        PLACEHOLDERClassName={inputPlaceholderClassName}
        className={inputPropertyDefaultClassName}
        property1="default"
        placeholderText={placeholderText}
        text={text}
        onChange={onChange}
        value={value}
        name={name}
        type={type}
        minlength={minlength}
        id={id}
      />
    </div>
  );
};

InputLabel.propTypes = {
  text: PropTypes.string,
  inputText: PropTypes.string,
};
