/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { Avatar1 } from "../../icons/Avatar1";
import Frame212 from "../../assets/frame-21-2.svg";
import Frame172 from "../../assets/frame-17-2.svg";
import "./style.css";

export const Quote = ({
  className,
  frame = { Frame172 },
  divClassName,
  text = "For our mobile app testing, we used to employ QAs with programming skills. Now, our QAs no longer need to know programming. With TRAILBLU, we can automate all our tests and get our product to market faster.",
  icon = <Avatar1 className="avatar-1" />,
  text1 = "Nilay Kose",
  text2 = "Test and Quality Manager, Fibabanka",
}) => {
  return (
    <div className={`quote ${className}`}>
      <div className="TOP">
        <img className="img" alt="Trailblu Frame-21-2" src={Frame212} />
        <div className="for-our-mobile-app-wrapper">
          <p className="for-our-mobile-app">{text}</p>
        </div>
        <img className="frame-2" alt="Trailblu Frame-2" src={frame} />
      </div>
      <div className="BOTTOM">
        {icon}
        <div className="div">
          <div className={`nilay-kose ${divClassName}`}>{text1}</div>
          <p className="test-and-quality">{text2}</p>
        </div>
      </div>
    </div>
  );
};

Quote.propTypes = {
  frame: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
};
