import React from "react";
import ReactDOMClient from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import Main from "./screens/Main/Main";
import AlternativesPage from "./screens/Alternatives/Alternatives";
import ComparisonPage from "./screens/ComparisonPage/ComparisonPage";
import { Blog } from "./screens/Blog/Blog";
import SpecificBlog from "./screens/SpecificBlog/SpecificBlog";
import FeaturePage from "./screens/FeaturePage/Feature";
import RequestADemo from "./screens/RequestADemo/RequestADemo";
import NotFoundPage from "./screens/NotFoundPage/NotFoundPage";
import ThanksForSubmission from "./screens/ThanksForSubmission/ThanksForSubmission";
import { createSlug, blogPosts } from "./blogData";

export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/alternatives/:companyName" element={<ComparisonPage />} />
          <Route path="/feature/:featureName" element={<FeaturePage />} />
          <Route path="/alternatives" element={<AlternativesPage />} />
          <Route path="/request-a-demo" element={<RequestADemo />} />
          <Route path="/thank-you/submission" element={<ThanksForSubmission />} />
          {blogPosts.map((post, index) => (
            <Route
              key={index}
              path={`/blog/${createSlug(post.title)}`}
              element={
                <SpecificBlog
                  label={post.label}
                  title={post.title}
                  metaDescription={post.metaDescription}
                  metaTitle={post.metaTitle}
                  content={post.content}
                  authorName={post.author}
                  date={post.date}
                  readTime={post.readTime}
                  authorImageUrl={post.authorImageUrl}
                  blogImage={post.blogImage}
                />
              }
            />
          ))}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(<App />);
