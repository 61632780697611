import React from "react";
import "./style.css";

export const ComparisonTable = (props) => {
  const { company, comparisonList } = props;

  const renderContent = (value) => {
    if (value?.toLowerCase() === "yes") {
      return <span className="tick">✓</span>;
    } else if (value?.toLowerCase() === "no") {
      return <span className="cross">✗</span>;
    } else {
      return value;
    }
  };

  return (
    <div className="comparison-table">
      <div className="table-header">
        <p className="table-title">Feature Comparison: Trailblu vs {company}</p>
      </div>
      <div className="table-header">
        <div className="table-header-feature">Features</div>
        <div className="pinkish-color-feature">{company}</div>
        <div className="greenish-color-feature">Trailblu</div>
      </div>

      {comparisonList.map((item, index) => (
        <div className="table-row" key={index}>
          <div>{item.feature}</div>
          <div className="pinkish-color">
            {renderContent(item.otherCompany?.toLowerCase())}
          </div>
          <div className="greenish-color">
            {renderContent(item.trailblu?.toLowerCase())}
          </div>
        </div>
      ))}
    </div>
  );
};
