import React from "react";
import FooterIcon from "../../assets/footer.png";
import Capterra from "../../assets/capterra.svg";
import GetApp from "../../assets/getApp.png";
import { Link } from "react-router-dom";
import "./style.css";

const Footer = () => {
  return (
    <div className="frame-29">
      <div className="footer">
        <img
          src={FooterIcon}
          className="tb-1-1"
          color="#B4C6E7"
          fill="white"
          alt="Trailblu Footer Icon"
        />

        <p className="text-wrapper-15">
          Copyright © {new Date().getFullYear()} TRAILBLU, Inc. All rights reserved
        </p>
        <div className="frame-30">
          <div className="frame-31">
            <div className="text-wrapper-16">Resources</div>
            <Link to="/blog">
              <div className="text-wrapper-17">Blog</div>
            </Link>
          </div>
          <div className="frame-31">
            <div className="text-wrapper-compare">Compare Us</div>
            <Link to="/alternatives">
              <div className="text-wrapper-17">Alternatives</div>
            </Link>
          </div>

          <div className="frame-31">
            <div className="text-wrapper-16">Follow us</div>
            <div style={{ display: "flex", gap: "20px" }}>
              <a href="https://twitter.com/trailblu" target="_blank">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.5em"
                  viewBox="0 0 512 512"
                >
                  <g fill="#ffffff">
                    <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                  </g>
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/trailblu/"
                target="_blank"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1.5em"
                  viewBox="0 0 448 512"
                >
                  <g fill="#ffffff">
                    <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="comment-bottom">
          <a
            className="capterra"
            target="_blank"
            href="https://www.capterra.com/p/10014205/TRAILBLU/#reviews"
          >
            <img
              src={Capterra}
              alt="Trailblu Capterra"
              style={{ borderRadius: "5%", display: "flex", width: "100%" }}
            />
          </a>
          <a
            className="getapp"
            target="_blank"
            href="https://www.getapp.com/it-management-software/a/trailblu/reviews/"
          >
            <img className="getapp-img" src={GetApp} alt="Trailblu GetApp" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
