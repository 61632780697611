import ProfileIcon from "./assets/profile.jpg";
import VisualTestingImage from "./assets/visualTesting.png";
import GenerativeAIimage from "./assets/generativeAI.png";
import QatarEvent from "./assets/qatar_event.png";
import HowToMasterImage from "./assets/p21.png";
import FlutterTestingImage from "./assets/flutterTesting.png";
import SelfHealingImage from "./assets/p31.png";
import RevolutionizeTestingAIImage from "./assets/revolutionizetestingAI1.png";
import CodelessAutomatedImage from "./assets/codelessAutomated.png";
import ContinuousTestingImage from "./assets/ContinuousTesting2.png";
import RegressionTestingImage from "./assets/RegressionTesting1.png";

import CodelessAutomated from "./Blogs/CodelessAutomated.md";
import QatarEventBlog from "./Blogs/QatarEvent.md";
import HowToMaster from "./Blogs/HowToMaster.md";
import TrailbluIntroduces from "./Blogs/TrailbluIntroduces.md";
import AIPoweredSelfHealing from "./Blogs/AIPoweredSelfHealing.md";
import GenerativeAI from "./Blogs/GenerativeAI.md";
import FlutterTesting from "./Blogs/FlutterTesting.md";
import RevolutionizeTestingAI from "./Blogs/RevolutinizeTestingAI.md";
import RevolutionizeTestingAI2 from "./Blogs/RevolutinizeTestingAI2.md";
import ContinuousTesting from "./Blogs/ContinuousTesting.md";
import RegressionTesting from "./Blogs/RegressionTesting.md";


const createSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^a-z0-9-]/g, "");
};

const blogPosts = [
    {
        label: "Testing Tools",
        tag: "Testing Tools",
        blogImage: RevolutionizeTestingAIImage,
        title: "Revolutionizing Software Testing with AI: Expanding the Horizons (Part 2)",
        metaTitle: "Software Testing with AI | Trailblu",
        metaDescription: "Discover how AI is transforming software testing, from performance and security testing to continuous testing and predictive analytics. Explore real-world examples and future trends in AI-augmented testing.",
        author: "Trailblu",
        date: "Sep 23, 2024",
        readTime: "5",
        content: RevolutionizeTestingAI2,
        authorImageUrl: ProfileIcon,
    },
    {
        label: "DevOps",
        tag: "DevOps",
        blogImage: RegressionTestingImage,
        title: "Regression Testing",
        metaTitle: "Regression Testing | Trailblu",
        metaDescription: "Discover why Regression Testing is crucial for enabling early bug detection, improved collaboration, and seamless code deployment with Trailblu's expert solutions.",
        author: "Trailblu",
        date: "Sep 3, 2024",
        readTime: "5",
        content: RegressionTesting,
        authorImageUrl: ProfileIcon,
    },
    {
        label: "DevOps",
        tag: "DevOps",
        blogImage: ContinuousTestingImage,
        title: "Why Continuous Testing is a Must-Have for Every Developer",
        metaTitle: "Why Continuous Testing is Essential for Every Developer | Trailblu",
        metaDescription: "Discover why Continuous Testing is crucial for developers to maintain speed and quality in software development. Learn how it enables early bug detection, improved collaboration, and seamless code deployment with Trailblu's expert solutions.",
        author: "Trailblu",
        date: "Aug 13, 2024",
        readTime: "5",
        content: ContinuousTesting,
        authorImageUrl: ProfileIcon,
    },
    {
        label: "Testing Tools",
        tag: "Testing Tools",
        blogImage: RevolutionizeTestingAIImage,
        title: "Revolutionizing Software Testing with AI: 6 Key Benefits for Your Business (Part 1)",
        metaTitle: "Software Testing with AI | Trailblu",
        metaDescription: "Discover how AI is transforming software testing, boosting efficiency, accuracy, and coverage. Explore 6 key benefits of AI-augmented testing in this insightful blog post.",
        author: "Trailblu",
        date: "July 30, 2024",
        readTime: "5",
        content: RevolutionizeTestingAI,
        authorImageUrl: ProfileIcon,
    },
  {
    label: "Testing Tools",
    tag: "Testing Tools",
    blogImage: GenerativeAIimage,
    title: "Unveiling the Future of Software Testing with Generative AI at Trailblu",
    metaTitle: "Generative AI in Software Testing | Trailblu",
    metaDescription: "Explore Generative AI’s role in software testing and test automation. Read about important use cases and Trailblu’s innovative approach!",
    author: "Trailblu",
    date: "January 29, 2024",
    readTime: "5",
    content: GenerativeAI,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Event",
    tag: "Event",
    blogImage: QatarEvent,
    title: "Web Summit Qatar: Trailblu's Insight into Future Tech",
    metaTitle: "Trailblu's Exciting Venture at Web Summit Qatar 2024: Unveiling Tech Innovations",
    metaDescription: "Join Trailblu at the Web Summit Qatar 2024 for industry insights and influential speakers. Explore our booth for tech innovations, partnerships, and key takeaways.",
    author: "Trailblu",
    date: "February 2, 2024",
    readTime: "3",
    content: QatarEventBlog,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Testing Tools",
    tag: "Testing Tools",
    blogImage: FlutterTestingImage,
    title: "Testing the Uncharted Territory of Flutter Apps",
    metaTitle: "Flutter Testing: Trailblu's Solution for Seamless Automation",
    metaDescription: "Elevate your Flutter app testing with Trailblu's innovative automation solution. Request a demo for a glimpse into the future of Flutter testing.",
    author: "Trailblu",
    date: "January 11, 2024",
    readTime: "3",
    content: FlutterTesting,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Visual Testing",
    tag: "Visual Testing",
    blogImage: VisualTestingImage,
    title: "Flawless UI Every Time: Introducing Trailblu's Visual Testing Revolution",
    metaTitle: "Visual Testing Feature for Superior UI Consistency | TRAILBLU",
    metaDescription: "Trailblu's Visual Testing feature is designed to enhance UI consistency across platforms. Automated, efficient, and precise – perfect for developers and designers.",
    author: "Trailblu",
    date: "November 25, 2023",
    readTime: "3",
    content: TrailbluIntroduces,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Codeless Automation",
    tag: "Codeless Automation",
    blogImage: HowToMasterImage,
    title: "Codeless Testing Best Practices: A Complete Guide to Mastery",
    metaTitle: "How to Master Codeless Test Automation | TRAILBLU",
    metaDescription: "Learn essential Trailblu tips for achieving effective and reliable tests, covering topics like test coverage, data quality, collaboration, and more.",
    author: "Trailblu",
    date: "April 5, 2023",
    readTime: "5",
    content: HowToMaster,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Test Automation",
    tag: "Test Automation",
    blogImage: SelfHealingImage,
    title: "AI-Powered Self-Healing: Tackling Test Flakiness Head-On",
    metaTitle: "AI-Powered Self-Healing for Test Flakiness | TRAILBLU",
    metaDescription: "Discover the impact of flaky locators and how AI-powered self-healing techniques revolutionize automated testing.",
    author: "Trailblu",
    date: "December 26, 2022",
    readTime: "6",
    content: AIPoweredSelfHealing,
    authorImageUrl: ProfileIcon,
  },
  {
    label: "Testing Tools",
    tag: "Testing Tools",
    blogImage: CodelessAutomatedImage,
    title: "Effortless Testing: The Future of Codeless Automation",
    metaTitle: "The Rise of Codeless Automation | TRAILBLU",
    metaDescription: "Streamline your testing process without coding, saving time and effort. Explore the simplicity of Trailblu for efficient mobile and web application testing.",
    author: "Trailblu",
    date: "December 5, 2022",
    readTime: "4",
    content: CodelessAutomated,
    authorImageUrl: ProfileIcon,
  },
  // Add more blog post objects here
];

export { createSlug, blogPosts };
