import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Helmet } from "react-helmet-async";
import { Button } from "../../components/Button";
import { IconsMill } from "../../icons/IconsMill";
import { IconsHouse1 } from "../../icons/IconsHouse1";
import { IconsHouse2 } from "../../icons/IconsHouse2";
import { IconsPlug1 } from "../../icons/IconsPlug1";
import { IconsCloudLaptop } from "../../icons/IconsCloudLaptop";
import { IconsPhone } from "../../icons/IconsPhone";
import MobileTesting from "../../assets/mobile-web-app-testing.svg";
import Worker from "../../assets/worker.png";
import NoCodeTestingGif from "../../assets/TrailbluNoCodeTesting.gif";
import SelfHealingGif from "../../assets/TrailbluSelfHealing.gif";
import VisualTestingGif from "../../assets/TrailbluVisualTesting.gif";
import GenAiGif from "../../assets/TrailbluGenAI.gif";
import "./style.css";
import killerGenAiImage from "../../assets/killerFeatureGenAI.png";
import killerNCImage from "../../assets/killerFeatureNoCode.png";
import killerSHImage from "../../assets/killerFeatureSelfHealing.png";
import killerVTImage from "../../assets/killerFeatureVisualTesting.png";
import nc1 from "../../assets/noCodeFeatureIcons/1write.svg";
import nc2 from "../../assets/noCodeFeatureIcons/2groupOfPeople.svg";
import nc3 from "../../assets/noCodeFeatureIcons/3RocketLaunching.svg";
import nc4 from "../../assets/noCodeFeatureIcons/4FixedSetting.svg";
import genai1 from "../../assets/genAiFeatureIcons/1puzzle-piece.svg";
import genai2 from "../../assets/genAiFeatureIcons/2stopwatch.svg";
import genai3 from "../../assets/genAiFeatureIcons/3shield.svg";
import genai4 from "../../assets/genAiFeatureIcons/4danger.svg";
import genai5 from "../../assets/genAiFeatureIcons/5transparency.svg";
import genai6 from "../../assets/genAiFeatureIcons/6quality.svg";
import vt1 from "../../assets/visualTestingFeatureIcons/1magnifying-glass.svg";
import vt2 from "../../assets/visualTestingFeatureIcons/2target.svg";
import vt3 from "../../assets/visualTestingFeatureIcons/3responsive.svg";
import vt4 from "../../assets/visualTestingFeatureIcons/4screenshot.svg";
import vt5 from "../../assets/visualTestingFeatureIcons/5analyzing.svg";
import sh1 from "../../assets/selfHealingFeatureIcons/1guarantee.svg";
import sh2 from "../../assets/selfHealingFeatureIcons/2automatic.svg";
import sh3 from "../../assets/selfHealingFeatureIcons/3shield-update.svg";
import sh4 from "../../assets/selfHealingFeatureIcons/4increasing-bar.svg";
import sh5 from "../../assets/selfHealingFeatureIcons/5decreasing-bar.svg";

const Feature = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { featureName } = useParams();

  const navigate = useNavigate();
  const formattedFeatureName = featureName
    ? featureName.split("-").join(" ") // Split the string by "-" and join using space
    : "";
  console.log(formattedFeatureName);
  const getFeatureData = (formattedFeatureName) => {
    switch (formattedFeatureName) {
      case "ai powered no code test automation":
        return {
          metaTitle:
            "Enhance Testing with AI-Powered No-Code Solution | TRAILBLU",
          metaDescription:
            "Elevate your testing with Trailblu's AI-Powered No-Code Test Automation. Effortlessly create and manage tests, ensuring reliability and top-quality software.",
          heroBannerTitle: "AI-Powered,",
          heroBannerTitle2: "No-Code,",
          heroBannerTitle3: "Test Automation",
          rightImg: NoCodeTestingGif,
          platformOverview:
            "Tired of tedious testing? Trailblu's AI-Powered solution revolutionizes the game, making testing effortless for developers. No more coding hassles, just quick tests, detailed results, and seamless integration. Elevate your testing game with Trailblu!",
          cta1: "Choose Trailblu and experience the peak of AI-Powered testing solutions, transforming your testing processes with unmatched precision!",
          H2: "Challenges Addressed by No-Code Test Automation",
          H2Content: [
            {
              title: "Simplifying Test Script Creation",
              content:
                "No-Code Test Automation eliminates the complexities of coding, making test script creation straightforward and user-friendly, even for complex applications like web, mobile, or virtual environments.",
              imageUrl: nc1,
            },
            {
              title: "Opening Testing to Non-Developers",
              content:
                "With No-Code solutions, deep programming knowledge is no longer a prerequisite, making test creation accessible to people of all skill levels. This inclusivity fosters a collaborative, agile workflow.",
              imageUrl: nc2,
            },
            {
              title: "Accelerating Test Development",
              content:
                "No-Code Test Automation speeds up the test creation process, removing the bottlenecks of complex coding and lengthy integrations. This efficiency helps in achieving quicker deployment and faster time-to-market, focusing on testing outcomes rather than repetitive tasks.",
              imageUrl: nc3,
            },
            {
              title: "Reducing Maintenance Hassles",
              content:
                "Trailblu’s platform simplifies test maintenance, minimizing the need for constant updates. It leverages AI for reusable test components, automatically adapting to changes, which ensures tests remain current with minimal manual intervention.",
              imageUrl: nc4,
            },
          ],
          killerFeatureTitle:
            "No-Code Test Automation for Effortless and Reliable Results",
          killerFeatureImage: killerNCImage,
          killerFeatureContent: (
            <div style={{ maxWidth: "1024px", textAlign: "justify" }}>
              <p style={{ marginTop: "40px" }} className="killer-content">
                The game-changing AI-Powered No-Code Test Automation, liberates
                users from the complexities of coding, making automated testing
                accessible to everyone, regardless of their technical
                background.
              </p>
              <h3
                className="killer-content"
                style={{
                  fontWeight: "650",
                  marginBottom: "25px",
                  marginTop: "32px",
                  fontSize: "24px",
                  color: "#3a3a3a",
                }}
              >
                How does Trailblu No-Code AI-Powered Test Automation Help your
                Business?
              </h3>
              <p className="killer-content">
                This unique capability empowers users to effortlessly create,
                execute, and manage automated tests, ensuring a quick and
                user-friendly testing experience. With detailed test results,
                seamless integration into existing pipelines, and a self-healing
                feature, Trailblu's No-Code Test Automation not only simplifies
                the testing process but also enhances reliability, making it an
                indispensable tool for ensuring the quality and efficiency of
                software products.
              </p>
            </div>
          ),
        };

      case "ai powered visual testing":
        return {
          metaTitle: "AI-Powered Visual Testing | TRAILBLU",
          metaDescription:
            "Explore the future of testing with Trailblu's transformative AI-Powered Visual Testing. Address consistency challenges, launch errors, and collaboration gaps easily. ",
          heroBannerTitle: "AI-Powered",
          heroBannerTitle2: "Visual Testing",
          rightImg: VisualTestingGif,
          platformOverview:
            "In a digital-centric era, Trailblu proudly unveils its groundbreaking feature: Automated Visual Testing. This revolutionary tool is poised to redefine how developers and designers ensure the visual consistency and functionality of websites and applications.",
          cta1: "Shape a visually flawless digital future with Trailblu. Explore our AI-Powered solutions for exceptional testing experiences.",
          H2: "Trailblu's Visual Mastery: Elevating UI with Innovation",
          H2Content: [
            {
              title: "Extensive Visual Assurance",
              content:
                "Trailblu’s Visual Testing ensures complete visual coverage of your UI. Experience advanced detection and grouping of visual changes across web and mobile applications, enhancing consistency and user experience.",
              imageUrl: vt1,
            },
            {
              title: "Precision Testing: Reducing False Positives",
              content:
                "Trailblu’s AI Visual Engine accurately spots crucial visual changes and minimizes false positives, focusing on key elements that impact users while ignoring what's not important.",
              imageUrl: vt2,
            },
            {
              title: "Adaptive Responsive Testing",
              content:
                "Trust your UI's adaptability on any device with Trailblu’s responsive testing. Assure excellence in user interfaces at every screen dimension, ensuring a universally seamless experience.",
              imageUrl: vt3,
            },
            {
              title: " Advanced Snapshot Technology",
              content:
                "Utilize Trailblu’s groundbreaking snapshot technology to achieve more accurate visual tests. It expertly handles dynamic content like animations and custom typography, delivering dependable, consistent results.",
              imageUrl: vt4,
            },
            {
              title: "Refined Visual Analysis Strategy",
              content:
                "Trailblu changes how you check visual updates. Its easy-to-use tools and smart analysis make the review faster and simpler, helping you make quick and smart choices.",
              imageUrl: vt5,
            },
          ],
          killerFeatureTitle: "Trailblu's AI-Powered Visual Testing –",
          killerFeatureTitle2: "A Gateway to Flawless UI",
          killerFeatureImage: killerVTImage,
          killerFeatureContent: (
            <div
              style={{
                marginTop: "40px",
                textAlign: "justify",
                maxWidth: "1024px",
              }}
            >
              <p className="killer-content">
                Trailblu's AI-Powered Visual Testing is a groundbreaking
                innovation reshaping the landscape of UI quality assurance. This
                advanced tool doesn't just identify visual discrepancies; it
                offers a comprehensive analysis that ensures every aspect of
                your UI is pixel-perfect. By seamlessly integrating into your
                development workflow, it provides invaluable insights into the
                visual fidelity of your applications.
              </p>
              <h3
                className="killer-content"
                style={{
                  fontWeight: "650",
                  marginBottom: "25px",
                  marginTop: "32px",
                  fontSize: "24px",
                  color: "#3a3a3a",
                }}
              >
                How does Trailblu AI-Powered Visual Testing Help your Business?
              </h3>
              <p className="killer-content">
                Trailblu’s AI-Driven visual testing revolutionizes the way you
                approach UI consistency and quality. Its intelligent algorithms
                analyze every detail, from color consistency to layout
                precision, reducing the manual effort needed for visual checks.
                This leads to a significant reduction in time and resources
                spent on quality assurance, making your testing process more
                efficient and cost-effective.
              </p>
              <p className="killer-content">
                With Trailblu's AI-Powered Visual Testing, you're not just
                adopting a tool; you're embracing a new standard of excellence
                in UI design. Enter a new era of visual perfection with Trailblu
                and set a benchmark in digital aesthetics.
              </p>
            </div>
          ),
        };

      case "ai powered self healing":
        return {
          metaTitle:
            "Revolutionizing Testing with AI-Powered Self-Healing | TRAILBLU",
          metaDescription:
            "Experience seamless automated testing with Trailblu's AI-Powered Self-Healing. Proactively tackle challenges like locator flakiness and reduce manual intervention.",
          heroBannerTitle: "AI-Powered",
          heroBannerTitle2: "Self-Healing",
          rightImg: SelfHealingGif,
          platformOverview:
            "Trailblu's AI-Powered Self-Healing transforms automated testing by using artificial intelligence to tackle challenges from dynamic application changes. This innovative tool proactively addresses issues like locator flakiness, ensuring test reliability without constant manual intervention.",
          cta1: "Explore the power of AI-Powered Self-Healing for a seamless and efficient testing process.",
          H2: "Challenges Addressed by Self-Healing",
          H2Content: [
            {
              title: "Locator Stability",
              content:
                "Our system expertly identifies and resolves issues with unstable locators, guaranteeing stable and consistent automated tests.",
              imageUrl: sh1,
            },
            {
              title: "Reduced Manual Maintenance",
              content:
                "Automatically modifies test scripts in response to updates in the application's layout, text, or other elements, ensuring fluid adaptation without manual effort.",
              imageUrl: sh2,
            },
            {
              title: "Reliable Test Performance",
              content:
                "Enhances test reliability by continuously syncing with ongoing application updates, improving accuracy and reliability.",
              imageUrl: sh3,
            },
            {
              title: "Increased Testing Efficiency",
              content:
                "Streamlines the testing process by autonomously handling issues related to locator changes, allowing testing teams to focus on higher-value tasks.",
              imageUrl: sh4,
            },
            {
              title: "Minimized Test Failures",
              content:
                "The self-healing feature significantly lowers the chances of test failures from UI updates, keeping tests current and maintaining steady project progress.",
              imageUrl: sh5,
            },
          ],
          killerFeatureTitle: "A Healing Hand in Testing:",
          killerFeatureTitle2: "Trailblu's AI-Powered Solution",
          killerFeatureImage: killerSHImage,
          killerFeatureContent: (
            <div
              style={{
                marginTop: "40px",
                textAlign: "justify",
                maxWidth: "1024px",
              }}
            >
              <p style={{ marginBottom: "20px" }} className="killer-content">
                AI-Powered Self-Healing is a revolutionary solution that
                proactively identifies and adjusts to failing locators, ensuring
                the accuracy and reliability of automated tests. This
                game-changing feature not only saves valuable time and resources
                but also streamlines the testing process for increased
                efficiency.
              </p>
              <h3
                style={{
                  fontWeight: "650",
                  marginBottom: "25px",
                  marginTop: "32px",
                  fontSize: "24px",
                  color: "#3a3a3a",
                }}
                className="killer-content"
              >
                How does Trailblu’s AI-Powered Self-Healing Help your Business?
              </h3>
              <p className="killer-content">
                Trailblu's AI-driven self-healing algorithm intelligently
                detects and repairs flaky locators, significantly reducing the
                need for manual intervention. This not only enhances the
                reliability of automated tests but also contributes to a more
                cost-effective and streamlined testing process.
              </p>
              <p className="killer-content">
                Beyond being a tool, it's a commitment to flawless testing
                experiences, empowering development teams to focus on critical
                tasks. Embrace the future of automated testing with Trailblu's
                AI-Powered Self-Healing.
              </p>
            </div>
          ),
        };

      case "generative ai powered test creation":
        return {
          metaTitle: "Generative AI-Powered Test Creation | TRAILBLU",
          metaDescription:
            "Elevate your testing with Trailblu's AI-Powered No-Code Testing. Effortlessly create and manage tests, ensuring reliability and top-quality software.",
          heroBannerTitle: "Generative",
          heroBannerTitle2: "AI-Powered",
          heroBannerTitle3: "Test Creation",
          rightImg: GenAiGif,
          platformOverview:
            "In the forefront of innovation, Trailblu introduces a revolutionary stride in software testing: Generative AI-Powered Test Creation. With the might of AI and the simplicity of natural language, this feature transforms test creation from a coding chore to a conversation with AI.",
          cta1: "Harness the Power of AI in Your Test Suites with Trailblu. Experience the future of test creation today.",
          H2: "Solving the Puzzle of Software Testing",
          H2Content: [
            {
              title: "Simplified Test Creation",
              content:
                "Bid farewell to complex scripting. Our AI understands your requirements and generates test cases accordingly.",
              imageUrl: genai1,
            },
            {
              title: "Speed and Efficiency",
              content:
                "AI speeds up test creation, enabling rapid iterations and swift project delivery.",
              imageUrl: genai2,
            },
            {
              title: "Making Test Creation Accessible to All",
              content:
                "Now, everyone on the team can contribute to test creation, regardless of their coding expertise.",
              imageUrl: genai3,
            },
            {
              title: "Reducing Human Error",
              content:
                "AI minimizes the mistakes inherent in manual test writing, leading to more reliable and consistent test cases.",
              imageUrl: genai4,
            },
            {
              title: "Transparent Test Scenarios",
              content:
                "With AI-generated tests, stakeholders get a clear understanding of what is being tested and why.",
              imageUrl: genai5,
            },
            {
              title: "Quality Assurance at Its Core",
              content:
                "Trailblu's AI ensures that each test case is geared towards the highest standards of quality and coverage.",
              imageUrl: genai6,
            },
          ],
          killerFeatureTitle:
            "Revolutionize Testing with Trailblu’s Generative AI",
          killerFeatureImage: killerGenAiImage,
          killerFeatureContent: (
            <div style={{ maxWidth: "1024px", textAlign: "justify" }}>
              <p style={{ marginTop: "40px" }} className="killer-content">
                Trailblu’s Generative AI-Powered Test Creation is not just a
                feature; it's a transformative force. It crafts detailed test
                cases from simple descriptions, ensuring accuracy and
                thoroughness. The AI's ability to parse natural language means
                that it's like having an expert tester interpreting your needs
                and turning them into reality.
              </p>
              <h3
                className="killer-content"
                style={{
                  fontWeight: "650",
                  marginBottom: "25px",
                  marginTop: "32px",
                  fontSize: "24px",
                  color: "#3a3a3a",
                }}
              >
                How Trailblu’s AI-Driven Test Creation Elevates Your Project?
              </h3>
              <p className="killer-content">
                Our platform’s intuitive AI uses advanced algorithms to generate
                comprehensive tests. This facilitates a collaborative
                environment where quality is everyone's business. The result? A
                robust, user-centric product that stands the test of time.
              </p>
              <p className="killer-content">
                Embrace the future of test creation with Trailblu. Step into an
                era where AI does the heavy lifting, and innovation is at your
                fingertips.
              </p>
            </div>
          ),
        };
    }
  };

  const renderFeature = (feature, index) => {
    const isImageLeft = index % 2 === 0;

    return (
      <div key={index} className="feature-list">
        {isImageLeft ? (
          <>
            <p className="feature-list-left-text-960">{feature.content}</p>
            <div className="feature-list-right-image-wrapper">
              <img src={feature.imageUrl} className="feature-list-img" />
            </div>
            <div className="feature-list-right">
              <h3 className="feature-list-left-subheading">{feature.title}</h3>
              <p className="feature-list-right-text">{feature.content}</p>
            </div>
          </>
        ) : (
          <>
            <div className="feature-list-left">
              <h3 className="feature-list-left-subheading">{feature.title}</h3>
              <p className="feature-list-left-text">{feature.content}</p>
            </div>
            <div className="feature-list-right-image-wrapper">
              <img src={feature.imageUrl} className="feature-list-img" />
            </div>
            <p className="feature-list-left-text-960">{feature.content}</p>
          </>
        )}
      </div>
    );
  };

  //make an available feature name list
  const featureData = getFeatureData(formattedFeatureName);
  return (
    <div className="feature-main">
      <Helmet>
        <title>{featureData.metaTitle}</title>
        <meta name="description" content={featureData.metaDescription} />
      </Helmet>
      <Header />
      {/** Title-introduction section */}
      <div className="introduction-container">
        <div className="double-side-intro">
          <div className="left-intro">
            <h1 className="text-intro">{featureData.heroBannerTitle}</h1>
            {featureData.heroBannerTitle2 && (
              <h1 className="text-intro">{featureData.heroBannerTitle2}</h1>
            )}
            {featureData.heroBannerTitle3 && (
              <h1 className="text-intro">{featureData.heroBannerTitle3}</h1>
            )}
            <p className="intro-body">{featureData.platformOverview}</p>
            {/** request a demo button */}
            <a href="/request-a-demo" className="feature-link">
              <Button
                className="button-2"
                stateProp="normal"
                style="solid"
                text="Request a demo"
                type="regular"
              />
            </a>
          </div>
          <div className="right-intro">
            <img src={featureData.rightImg || Worker} className="right-img" />
          </div>
        </div>
      </div>
      {/** feature details - image & text section */}
      <div className="frame-5">
        <p className="general-feature-title">{featureData.cta1}</p>
      </div>

      <div className="features">
        <div className="feature-container">
          {/**  <p className="feature-intro">
            Trailblu streamlines your automated test development so it’s never
            lagging behind the dev sprints, enabling you to focus on what
            matters most: delivering high-quality software at speed.
          </p>*/}
          <h2
            style={{
              fontSize: "36px",

              margin: "50px 8%",
              textAlign: "center",
              color: "#3a3a3a",
              lineHeight: "3rem",
            }}
          >
            {featureData.H2}
          </h2>

          {featureData.H2Content.map((feature, index) =>
            renderFeature(feature, index)
          )}

          {/**
          {featureData.H2Content.map((feature, index) => (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginTop: "48px",
                  marginLeft: "5%",
                  marginRight: "5%",
                }}
              >
                <h3>{feature.title}</h3>
                <p style={{ lineHeight: "2rem", fontSize: "20px" }}>
                  {feature.content}
                </p>
              </div>
            </>
          ))} */}
        </div>
      </div>
      <div className="killer-feature">
        <h1 className="h1-killer">{featureData.killerFeatureTitle}</h1>
        {featureData.killerFeatureTitle2 && (
          <h1 className="h1-killer">{featureData.killerFeatureTitle2}</h1>
        )}
        <div className="killer-duo">
          <img
            className="killer-feature-img"
            src={featureData.killerFeatureImage}
          />
          {featureData.killerFeatureContent}
        </div>

        {/**
        <div style={{ maxWidth: "1220px" }}>
          {featureData.killerFeatureContent}
        </div>
        */}
      </div>

      {/**frequently asked question */}
      <div className="frame-8-feature">
        <div className="text-wrapper-5">
          Real-World Impact: AI Solutions That Truly Help
        </div>

        <div className="frame-9">
          {!(formattedFeatureName == "generative ai powered test creation") && (
            <div className="CARD-2-copy-feature">
              <div className="frame-10">
                <h2 className="starter">Generative AI-Powered Test Creation</h2>
                {/* <IconsCloudLaptop className="icon-instance-node" /> */}
                <img
                  className="icon-instance-node-2"
                  alt="Trailblu mobile testing"
                  src={MobileTesting}
                />
              </div>
              <div className="frame-32-copy">
                <p className="automate-mobile-and-2">
                  Embrace the future of testing with our advanced GPT
                  technology, ensuring thorough coverage and reliability in
                  every aspect of your application.
                </p>
                <a
                  href="/feature/generative-ai-powered-test-creation"
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <Button
                    className="design-component-instance-node-2"
                    stateProp="normal"
                    style="outline"
                    text="Read more"
                    type="regular"
                  />
                </a>
              </div>
            </div>
          )}

          {!(formattedFeatureName == "ai powered no code test automation") && (
            <div className="CARD-2-copy-feature">
              <div className="frame-10">
                <h2 className="starter">AI-Powered No-Code Testing</h2>
                <IconsCloudLaptop className="icon-instance-node" />
              </div>
              <div className="frame-32-copy">
                <p className="automate-mobile-and-2">
                  Forget about tedious, time-consuming test writing. With
                  No-Code Testing, you can quickly write tests and get fast
                  insights.
                </p>
                <a
                  href="/feature/ai-powered-no-code-test-automation"
                  style={{ width: "100%" }}
                >
                  <Button
                    className="design-component-instance-node-2"
                    stateProp="normal"
                    style="outline"
                    text="Read more"
                    type="regular"
                  />
                </a>
              </div>
            </div>
          )}
          {!(formattedFeatureName == "ai powered visual testing") && (
            <div className="CARD-2-copy-feature">
              <div className="frame-10">
                <h2 className="starter">AI-Powered Visual Testing</h2>
                <IconsPhone className="icon-instance-node" />
              </div>
              <div className="frame-32-copy">
                <p className="automate-mobile-and-2">
                  Harness the power of AI to transform your visual quality
                  assurance. Our tool provides comprehensive coverage, intuitive
                  insights, and smart detection of even the most subtle visual
                  issues.
                </p>
                <a
                  href="/feature/ai-powered-visual-testing"
                  style={{ width: "100%" }}
                >
                  <Button
                    className="design-component-instance-node-2"
                    stateProp="normal"
                    style="outline"
                    text="Read more"
                    type="regular"
                  />
                </a>
              </div>
            </div>
          )}
          {!(formattedFeatureName == "ai powered self healing") && (
            <div className="CARD-2-copy-feature">
              <div className="frame-10">
                <h2 className="starter">AI-Powered Self-Healing</h2>
                <IconsPlug1 className="icon-instance-node" />
              </div>
              <div className="frame-32-copy">
                <p className="automate-mobile-and-2">
                  With TRAILBLU&#39;s AI-Powered self-healing, you can get more
                  from your existing tests by automatically identifying and
                  fixing flaky tests.
                </p>
                <a
                  href="/feature/ai-powered-self-healing"
                  style={{ width: "100%" }}
                >
                  <Button
                    className="design-component-instance-node-2"
                    stateProp="normal"
                    style="outline"
                    text="Read more"
                    type="regular"
                  />
                </a>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="frame-19">
        <div className="frame-20">
          <div className="text-wrapper-10">Get started</div>
          <p className="text-wrapper-11">Choose one of our plans</p>
        </div>
        <div className="frame-21">
          <div className="CARD-5">
            <div className="frame-10">
              <div className="starter">Starter</div>
              <IconsMill className="icon-instance-node" />
            </div>
            <div className="frame-32">
              <p className="automate-mobile-and-3">
                <span className="text-wrapper-12">Automate</span>
                <span className="text-wrapper-13">
                  {" "}
                  mobile and web app testing
                  <br />
                </span>
              </p>
              <a href="/request-a-demo">
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="solid"
                  text="Request a demo"
                  type="regular"
                />
              </a>
              {/* <a href="#section7" style={{ width: "100%" }}>
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="solid"
                  text="Request a demo"
                  type="regular"
                  width="100%"
                />
              </a> */}
            </div>
          </div>
          <div className="CARD-5">
            <div className="frame-10">
              <div className="starter">Business</div>
              <IconsHouse1 className="icon-instance-node" />
            </div>
            <div className="frame-32">
              <p className="automate-mobile-and-3">
                <span className="text-wrapper-12">Automate</span>
                <span className="text-wrapper-13">
                  {" "}
                  mobile and web app testing
                  <br />
                </span>
                <span className="text-wrapper-12">Professional features</span>
                <span className="text-wrapper-13"> for remote teams</span>
              </p>

              <a href="/request-a-demo">
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="solid"
                  text="Request a demo"
                  type="regular"
                />
              </a>

              {/* <a href="#section7" style={{ width: "100%" }}>
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="solid"
                  text="Request a demo"
                  type="regular"
                />
              </a> */}
            </div>
          </div>
          <div className="CARD-5">
            <div className="frame-10">
              <div className="starter">Enterprise</div>
              <IconsHouse2 className="icon-instance-node" />
            </div>
            <div className="frame-32">
              <p className="automate-mobile-and-3">
                <span className="text-wrapper-12">Automate</span>
                <span className="text-wrapper-13">
                  {" "}
                  mobile and web app testing
                  <br />
                </span>
                <span className="text-wrapper-12">Professional features</span>
                <span className="text-wrapper-13">
                  {" "}
                  for remote teams
                  <br />
                </span>
                <span className="text-wrapper-12">Advanced features</span>
                <span className="text-wrapper-13">
                  {" "}
                  for large teams, priority support{" "}
                </span>
              </p>

              <a href="/request-a-demo">
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="solid"
                  text="Request a demo"
                  type="regular"
                />
              </a>

              {/* <a href="#section7" style={{ width: "100%" }}>
                <Button
                  className="design-component-instance-node-2"
                  stateProp="normal"
                  style="solid"
                  text="Request a demo"
                  type="regular"
                />
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Feature;
